// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sc-circular-progress__root___3M4Yt {\n  position: relative;\n  margin-bottom: -0.5rem;\n}\n.sc-circular-progress__bottom___kQy3i {\n  color: #dce2e4;\n}\n.sc-circular-progress__top___ZeRgT {\n  color: #a7b5bb;\n  animation-duration: 550ms;\n  position: absolute;\n  left: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "sc-circular-progress__root___3M4Yt",
	"bottom": "sc-circular-progress__bottom___kQy3i",
	"top": "sc-circular-progress__top___ZeRgT"
};
module.exports = exports;
