import cookie from 'cookie';
import {parseJsonOrReturnString} from 'utils/string-utils';

import forIn from 'lodash/forIn';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

const _cookies = new WeakMap();
let instance = null;
const COOKIE_OPTIONS = ['domain', 'encode', 'expires', 'httpOnly', 'maxAge', 'path', 'sameSite', 'secure'];

export default class MeteorCookies {
    constructor(options) {
        if (isNil(instance)) {
            instance = this;
        }
        this.hasDocumentCookie = MeteorCookies.hasDocumentCookie();
        this._options = {};
        this._refreshCookies();
        if (!isNil(options)) {
            this.setDefaultOptions(options);
        }

        return instance;
    }

    static hasDocumentCookie() {
        let result = false;
        if (isObject(document) && isString(document.cookie)) {
            result = true;
        }
        return result;
    }

    // here is a lovely hack!
    // we created no way to clear just cookies associated with the logged in user's session
    // which means any other cookies that we want to persist after logout will also be wiped
    // so here we explicity enumerate the ones we want to keep
    static wipeDocumentCookies() {
        if (MeteorCookies.hasDocumentCookie()) {
            const cookies = document.cookie.split(';');
            // eslint-disable-next-line no-shadow -- SCLD-17998
            cookies.forEach((cookie) => {
                if (
                    !cookie.includes('examInterfaceToken=') && // don't clear examInterfaceToken
                    !cookie.includes('centerSignInKioskToken=') &&
                    !cookie.includes('clientId')
                ) {
                    // don't clear centerSignInKioskToken
                    document.cookie = cookie
                        .replace(/^ +/, '')
                        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
                }
            });
        }
    }

    // because of the hack above, we can't voluntarily keep cookies in several places they get removed
    // so here we are... storing them so we can restore them later
    storeCookies() {
        if (MeteorCookies.hasDocumentCookie()) {
            this.storedCookies = document.cookie.split(';');
        }
    }

    restoreCookies() {
        if (!isNil(this.storedCookies)) {
            MeteorCookies.wipeDocumentCookies();
            // eslint-disable-next-line no-shadow -- SCLD-17998
            this.storedCookies.forEach((cookie) => {
                cookie = `${cookie};path=/`;
                document.cookie = cookie;
            });
        }
    }

    static getInstance() {
        return instance;
    }

    _refreshCookies() {
        if (this.hasDocumentCookie) {
            _cookies.set(instance, cookie.parse(document.cookie));
        }
    }

    setDefaultOptions(options) {
        forIn(options, (value, key) => {
            if (COOKIE_OPTIONS.indexOf(key) !== -1) {
                this._options[key] = value;
            }
        });
    }

    get cookies() {
        this._refreshCookies();
        const result = {};
        forIn(_cookies.get(instance), (cookieValue, cookieKey) => {
            result[cookieKey] = this.get(cookieKey);
        });
        return result;
    }

    get(key, nilResult = undefined) {
        // TODO: add getOptions (response) to return options
        this._refreshCookies();
        let result = nilResult;
        const cookies = _cookies.get(instance);
        if (!isNil(cookies[key])) {
            result = parseJsonOrReturnString(cookies[key]);
        }
        return result;
    }

    set(key, value, options) {
        if (isObject(value)) {
            value = JSON.stringify(value);
        }
        options = {
            ...this._options,
            ...options,
        };
        if (this.hasDocumentCookie) {
            document.cookie = cookie.serialize(key, value, options);
        }
        this._refreshCookies();

        return instance;
    }

    remove(key, options) {
        options = {
            maxAge: 0,
            ...this._options,
            ...options,
        };

        const cookies = _cookies.get(instance);
        delete cookies[key];

        if (this.hasDocumentCookie) {
            document.cookie = cookie.serialize(key, '', options);
        }

        return instance;
    }
}
