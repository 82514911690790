import type {Shadows} from '@material-ui/core/styles/shadows';
import {default as defaultShadows} from '@material-ui/core/styles/shadows';

const shadows: Shadows = defaultShadows;
shadows[1] = '0 0.2rem 0.4rem rgba(0, 0, 0, 0.15)';
shadows[2] = '0 0.4rem 0.8rem rgba(0, 0, 0, 0.15)';
shadows[3] = '0 0.8rem 1.6rem rgba(0, 0, 0, 0.15)';
shadows['centered'] = '0 0.4rem 1rem rgba(0, 0, 0, 0.15), 0 0 0.6rem rgba(0, 0, 0, 0.15)';

export default shadows;
