// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/layout.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/border.less");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
var ___CSS_LOADER_ICSS_IMPORT_3___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/typography.less");
var ___CSS_LOADER_ICSS_IMPORT_4___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/form.less");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("images/sc-logo-short.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_3___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_4___, "", true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".reset-password__main___HFmE2 {\n  width: 65rem;\n}\n.reset-password__label___2YTRV {\n}\n.reset-password__passwordResetSuccessful___32cep {\n  padding: 1.9rem 3.4rem 0rem 3.4rem;\n}\n.reset-password__form___LGrmM {\n  top: calc(50vh - 25rem);\n  padding: 1.9rem 0 2.9rem 0;\n  position: relative;\n}\n.reset-password__form___LGrmM:before {\n  content: '';\n  display: block;\n  height: 4.5rem;\n  margin-top: 2rem;\n  background-size: contain;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") scroll no-repeat 50% 0;\n}\n.reset-password__inner___DiPqR {\n  padding: 0 3.4rem;\n}\n.reset-password__controlsContainer___4R6qd {\n  text-align: right;\n  margin-top: 2rem;\n}\n.reset-password__controlsContainer___4R6qd > button:not(:last-of-type) {\n  margin-right: 2rem;\n}\n.reset-password__errorMessage___276_u {\n  padding-top: 1.5rem;\n}\n.reset-password__errorText___2C9Rx {\n}\n.reset-password__input___2W1Lg {\n  width: 29rem;\n}\n.reset-password__resetDescription___2tvCL {\n  padding: 1.5rem;\n  text-align: center;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "reset-password__main___HFmE2 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["center"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["fillHeightMinusFooter"] + "",
	"label": "reset-password__label___2YTRV " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["uppercase"] + " " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["tiny"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["darkGray"] + "",
	"passwordResetSuccessful": "reset-password__passwordResetSuccessful___32cep",
	"form": "reset-password__form___LGrmM " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["basic"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["whiteBG"] + "",
	"inner": "reset-password__inner___DiPqR " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["basicTop"] + "",
	"controlsContainer": "reset-password__controlsContainer___4R6qd " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["clear_floats"] + "",
	"errorMessage": "reset-password__errorMessage___276_u",
	"errorText": "reset-password__errorText___2C9Rx " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["error"] + " " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["small"] + "",
	"input": "reset-password__input___2W1Lg " + ___CSS_LOADER_ICSS_IMPORT_4___.locals["inputValid"] + "",
	"resetDescription": "reset-password__resetDescription___2tvCL " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["normal"] + ""
};
module.exports = exports;
