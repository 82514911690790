// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/border.less");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/typography.less");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
// Module
exports.push([module.id, ".error-boundary__error___27E87 {\n  padding: 2rem;\n}\n", ""]);
// Exports
exports.locals = {
	"error": "error-boundary__error___27E87 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["whiteBG"] + " " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["basicTable"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["medium"] + ""
};
module.exports = exports;
