import globalMessages from 'intl/global-messages';
import {useIntl} from 'react-intl';
import {useEffect} from 'react';

import type {MessageDescriptor} from 'react-intl';

const useDocumentTitle = (messageDescriptor?: MessageDescriptor) => {
    const {formatMessage} = useIntl();
    useEffect(() => {
        if (messageDescriptor) {
            document.title = formatMessage(globalMessages.documentTitleWithSimCapture, {
                documentTitle: formatMessage(messageDescriptor),
            });
        } else {
            document.title = formatMessage(globalMessages.simCaptureTitle);
        }
    }, [messageDescriptor, formatMessage]);
};

export default useDocumentTitle;
