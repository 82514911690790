import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {routerActions} from 'actions/router-actions';
import {bindActionCreators} from 'redux';

import {setUserSessionAuthenticationData, getSettings} from 'actions/session-actions';
import * as AuthApi from 'api/auth-api';
import * as ClientsApi from 'api/clients-api';
import * as SettingsApi from 'api/settings-api';
import SimCaptureApis from 'constants/simcapture-apis';

import SCButton from 'components/sc-button';
import SCCircularProgress from 'components/sc-circular-progress';
import SCLogoShort from 'images/sc-logo-short.svg';

import IntlUtils from 'utils/intl-utils';

import messages from 'intl/anonymous-messages';
import {useFlags} from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme) => {
    return {
        layout: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0 auto',
            minHeight: 'calc(100vh - 5rem)',
            width: '80rem',
        },
        paper: {
            alignItems: 'center',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
        },
        progress: {
            alignItems: 'top',
            display: 'flex',
        },
        progressText: {
            paddingLeft: theme.spacing(1),
        },
        laerdalLoginText: {
            marginBottom: theme.spacing(2),
        },
        simCaptureLogo: {
            backgroundSize: 'contain',
            height: '4.5rem',
            marginBottom: theme.spacing(2),
        },
    };
});

// eslint-disable-next-line no-shadow -- SCLD-17998
const LoginLaerdal = ({getSettings, replace, setUserSessionAuthenticationData}) => {
    const intl = useIntl();
    const flags = useFlags();
    const {formatMessage} = intl;
    const [inProgress, setInProgress] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        // This global function gets called once the gigya SDK has loaded
        // @ts-expect-error TS(2339) FIXME: Property 'onGigyaServiceReady' does not exist on t... Remove this comment to see the full error message
        window.onGigyaServiceReady = onGigyaLoaded;
        initialize();
    }, []);

    const initialize = async () => {
        const environmentSettings = await SettingsApi.fetchEnvironment();

        const gigyaSdkUrl = environmentSettings.get(SimCaptureApis.GIGYA_SDK);
        if (gigyaSdkUrl) {
            loadGigyaSDK(gigyaSdkUrl);
        }
    };

    // #region Gigya calls

    /**
     * Loads the Gigya SDK into the DOM. Uses the URL given by the SimCapture API.
     * @param {string} sdkUrl
     */
    const loadGigyaSDK = (sdkUrl) => {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.src = sdkUrl;
        document.getElementsByTagName('head')[0].appendChild(scriptTag);
    };

    const onGigyaLoaded = () => {
        // @ts-expect-error TS(2339) FIXME: Property 'gigya' does not exist on type 'Window & ... Remove this comment to see the full error message
        window.gigya?.socialize?.addEventHandlers({
            // the onLogin event happens after the redirect back to this page from the SSO login
            onLogin: fetchGigyaJwt,
        });
    };

    const fetchGigyaJwt = async () => {
        setInProgress(true);
        // @ts-expect-error TS(2339) FIXME: Property 'gigya' does not exist on type 'Window & ... Remove this comment to see the full error message
        window.gigya?.accounts?.getJWT({
            fields: 'firstName, lastName, email',
            expiration: 3600,
            callback: async (res) => {
                if (res.errorCode === 0) {
                    const lcIdToken = res.id_token;
                    const userSessionInfo = await AuthApi.laerdalConnectLogin(lcIdToken);
                    onLoginSuccess(userSessionInfo);
                } else {
                    console.error('error!', res);
                }
            },
        });
    };

    const laerdalLogin = () => {
        // @ts-expect-error TS(2339) FIXME: Property 'gigya' does not exist on type 'Window & ... Remove this comment to see the full error message
        window.gigya?.sso?.login({
            authFlow: 'redirect',
            redirectURL: window.location.href,
            useChildContext: true,
            context: {
                application: 'SimCapture',
                lang: intl.locale,
            },
        });
    };

    // #endregion Gigya calls

    const onLoginSuccess = async (userSessionInfo) => {
        const authToken = userSessionInfo.get('authToken');
        const clientId = userSessionInfo.get('clientId');
        const userId = userSessionInfo.get('userId');

        const client = await ClientsApi.fetchClient(clientId, userId, authToken);

        // puts the auth info into the session store
        setUserSessionAuthenticationData({
            token: authToken,
            userId,
            clientId,
            institutionName: client.get('institutionName'),
        });

        const goToNextPage = () => {
            replace('/');
        };

        const successFn = (settings) => {
            IntlUtils.updateTimeFormats(settings);
            IntlUtils.updateLocaleReloadAll(goToNextPage, flags.useSmartling);
        };

        const failFn = () => {
            goToNextPage();
        };

        getSettings(successFn, failFn);
    };

    return (
        <div className={classes.layout}>
            <Paper className={classes.paper}>
                <img className={classes.simCaptureLogo} src={SCLogoShort} />
                {inProgress && (
                    <div className={classes.progress}>
                        <SCCircularProgress size='small' />
                        <Typography className={classes.progressText}>
                            {formatMessage(messages.authenticating)}
                        </Typography>
                    </div>
                )}

                <Typography className={classes.laerdalLoginText}>{formatMessage(messages.laerdalLoginText)}</Typography>
                <SCButton
                    variant='primary'
                    disabled={false}
                    inProgress={inProgress}
                    onClick={laerdalLogin}
                    label={formatMessage(messages.login)}
                />
            </Paper>
        </div>
    );
};

LoginLaerdal.propTypes = {
    getSettings: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    setUserSessionAuthenticationData: PropTypes.func.isRequired,
};

LoginLaerdal.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSettings,
            setUserSessionAuthenticationData,
            ...routerActions,
        },
        dispatch,
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginLaerdal);
