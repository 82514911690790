import legacyColorMixin from 'mui/mixins/legacy-colors-mixin';
import legacyLayoutMixin from 'mui/mixins/legacy-layout-mixin';
import legacyTypographyMixin from 'mui/mixins/legacy-typography-mixin';

import type {Mixins as MuiMixins} from '@material-ui/core/styles/createMixins';

declare module '@material-ui/core/styles/createMixins' {
    export interface Mixins {
        legacyColors: typeof legacyColorMixin;
        legacyLayout: typeof legacyLayoutMixin;
        legacyTypography: typeof legacyTypographyMixin;
    }
}

const mixins: Omit<MuiMixins, 'gutters' | 'toolbar'> = {
    legacyColors: legacyColorMixin,
    legacyLayout: legacyLayoutMixin,
    legacyTypography: legacyTypographyMixin,
};

export default mixins;
