export const SIMCAPTURE = 'simCapture' as const;
export const EXAM_SYSTEM = 'examSystem' as const;
export const REPORTING_SERVICE = 'reportingService' as const;
export const VERA_SOCKET = 'veraSocket' as const;
export const GIGYA_SDK = 'gigyaSdk' as const;
export const INVENTORY_MANAGEMENT_SERVICE = 'inventoryManagementService' as const;

export default {
    SIMCAPTURE,
    EXAM_SYSTEM,
    REPORTING_SERVICE,
    VERA_SOCKET,
    GIGYA_SDK,
    INVENTORY_MANAGEMENT_SERVICE
} as const;
