import noop from 'lodash/noop';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';

import SCModal from 'components/modals/sc-modal';

import globalMessages from 'intl/global-messages';
import ModalContent from 'components/modals/components/modal-content';
import ModalTitle from 'components/modals/components/modal-title';
import ModalActions from 'components/modals/components/modal-actions';
import SCButton from 'components/sc-button';

const AcknowledgementModal = ({modalTitle, open, onClose, onExited, size, children}) => {
    const {formatMessage} = useIntl();

    return (
        <SCModal open={open} size={size} onClose={onClose} onExited={onExited}>
            <ModalTitle title={modalTitle} />
            <ModalContent>{children}</ModalContent>
            <ModalActions>
                <SCButton
                    variant='secondary'
                    onClick={onClose}
                    label={formatMessage(globalMessages.close)}
                    disabled={false}
                    inProgress={false}
                />
            </ModalActions>
        </SCModal>
    );
};

AcknowledgementModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    children: PropTypes.any,
};

AcknowledgementModal.defaultProp = {
    size: 'medium',
    onExited: noop,
};

export default AcknowledgementModal;
