import PropTypes from 'prop-types';

// The shape of the form manager instance exposed on its context. Note that the ENTIRE form manager is actually passed
// down in the context, which has many internal methods that should not be exposed, but what is defined here is the API
// that we intend to expose to form controls from the form manager.
export default PropTypes.shape({
    addControl: PropTypes.func.isRequired,
    isRegistered: PropTypes.func.isRequired,
    onChanged: PropTypes.func.isRequired,
    onInitialized: PropTypes.func.isRequired,
    onTouched: PropTypes.func.isRequired,
    removeControl: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    updateValidity: PropTypes.func.isRequired,
});
