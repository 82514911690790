// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/layout.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/effects.less");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/border.less");
var ___CSS_LOADER_ICSS_IMPORT_3___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
var ___CSS_LOADER_ICSS_IMPORT_4___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/typography.less");
var ___CSS_LOADER_ICSS_IMPORT_5___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/form.less");
var ___CSS_LOADER_ICSS_IMPORT_6___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/buttons.less");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("images/sc-logo-short.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_3___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_4___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_5___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_6___, "", true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".invalid-password__main___10rkA {\n  width: 65rem;\n}\n.invalid-password__link___32r_V {\n}\n.invalid-password__container___1j0uo {\n  top: calc(50vh - 25rem);\n  padding: 1.9rem 0 2.9rem 0;\n  position: relative;\n}\n.invalid-password__container___1j0uo:before {\n  content: '';\n  display: block;\n  height: 4.5rem;\n  margin-top: 2rem;\n  margin-bottom: 1.5rem;\n  background-size: contain;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") scroll no-repeat 50% 0;\n}\n.invalid-password__inner___3TVN1 {\n  padding: 0 3.4rem;\n}\n.invalid-password__helpText___3lazT,\n.invalid-password__resetSuccess___3B8BW {\n  text-align: center;\n}\n.invalid-password__helpText___3lazT {\n  margin: 0 3.4rem 2rem;\n}\n.invalid-password__resetSuccess___3B8BW {\n  margin: 2rem 3.4rem 0;\n}\n.invalid-password__returnToLoginLink___2YKYB {\n}\n.invalid-password__errorText___3dzem {\n  margin-top: 2rem;\n}\n.invalid-password__controls___yY7Q3 {\n  margin-top: 2rem;\n  display: flex;\n  flex-direction: row;\n}\n.invalid-password__buttonIcon___1ICnI {\n}\n.invalid-password__buttonContent___1REFv {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n.invalid-password__saveButton___1JLRw {\n}\n.invalid-password__cancelButton___DIfz1 {\n  margin-left: 2rem;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "invalid-password__main___10rkA " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["center"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["fillHeightMinusFooter"] + "",
	"link": "invalid-password__link___32r_V " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["hoverUnderline"] + " " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["brandBlue"] + "",
	"container": "invalid-password__container___1j0uo " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["basic"] + " " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["whiteBG"] + "",
	"inner": "invalid-password__inner___3TVN1 " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["basicTop"] + "",
	"helpText": "invalid-password__helpText___3lazT " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["black"] + " " + ___CSS_LOADER_ICSS_IMPORT_4___.locals["normal"] + "",
	"resetSuccess": "invalid-password__resetSuccess___3B8BW " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["black"] + " " + ___CSS_LOADER_ICSS_IMPORT_4___.locals["normal"] + "",
	"returnToLoginLink": "invalid-password__returnToLoginLink___2YKYB " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["hoverUnderline"] + " " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["brandBlue"] + "",
	"errorText": "invalid-password__errorText___3dzem " + ___CSS_LOADER_ICSS_IMPORT_5___.locals["errorMessage"] + "",
	"controls": "invalid-password__controls___yY7Q3 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["clear_floats"] + "",
	"buttonIcon": "invalid-password__buttonIcon___1ICnI " + ___CSS_LOADER_ICSS_IMPORT_6___.locals["buttonIcon"] + "",
	"buttonContent": "invalid-password__buttonContent___1REFv " + ___CSS_LOADER_ICSS_IMPORT_4___.locals["medium"] + "",
	"saveButton": "invalid-password__saveButton___1JLRw " + ___CSS_LOADER_ICSS_IMPORT_6___.locals["buttonPrimary"] + "",
	"cancelButton": "invalid-password__cancelButton___DIfz1 " + ___CSS_LOADER_ICSS_IMPORT_6___.locals["buttonSecondary"] + ""
};
module.exports = exports;
