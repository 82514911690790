import * as ApiUtils from 'utils/api-utils';

/**
 * Logs the user in to SimCapture based on the JWT given to them by Laerdal Connect
 * @param {string} lcIdToken
 * @returns
 */
export const laerdalConnectLogin = (lcIdToken) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/auth/laerdal-connect/login`,
        method: 'POST',
        data: {
            lcIdToken,
        },
    });
};
