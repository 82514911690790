import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import * as SessionActions from 'actions/session-actions';
import * as UserSessionApi from 'api/user-session-api';

// components
import LoginProgress from 'components/login-progress';

// constants
import anonymousMessages from 'intl/anonymous-messages';

/**
 * The user arrives here after successfully logging in via impersonation. The data of the impersonated user must be
 * loaded in a separate page that the impersonated user has access to, to avoid triggering rendering errors during the
 * process of loading all the necessary information before rendering their dashboard.
 */
const ImpersonateUserSuccess = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const {formatMessage} = intl;

    const onImpersonateUserSuccess = async () => {
        try {
            const settings = await UserSessionApi.fetchSettings();
            const userProfile = await UserSessionApi.fetchUserProfile();
            const userPermissions = await UserSessionApi.fetchUserPermissions();
            const userPreferences = await UserSessionApi.fetchUserPreferences();

            dispatch(SessionActions.setUserSessionData(settings, userProfile, userPermissions, userPreferences));
            history.replace('/');
        } catch (err) {
            console.error(err);
        }
    };

    // executes once upon initial load
    useEffect(() => {
        onImpersonateUserSuccess();
    }, []);

    return <LoginProgress messageText={formatMessage(anonymousMessages.authenticating)} />;
};

export default ImpersonateUserSuccess;
