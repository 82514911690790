import PropTypes from 'prop-types';

/**
 * Base palette colors for all graphs.
 */
export const GRAPH_COLORS = ['#9E4CA6', '#F2385A', '#FA9200', '#47B07B', '#B4D96A', '#4AD9D9', '#063C68'];

const GRAPH_ANIM_LENGTH = 400;
const GRAPH_EASE = 'easeCubicOut';

export const MINI_X_LABEL_TICK_LENGTH = 14;
export const MINI_X_LABEL_X_OFFSET = 4;
export const MINI_Y_LABEL_Y_OFFSET = 6;

export const MIN_LABEL_SPACING = 100;
export const MINI_POINT_RADIUS = 4;

export const graphPropTypes = {
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    xLabel: PropTypes.func,
    yLabel: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    animationDuration: PropTypes.number,
    ease: PropTypes.string,
    color: PropTypes.string,
};

export const graphDefaultProps = {
    x: undefined,
    y: undefined,
    xLabel: undefined,
    width: 600,
    height: 400,
    animationDuration: GRAPH_ANIM_LENGTH,
    ease: GRAPH_EASE,
};
