import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';

import SCCircularProgress from 'components/sc-circular-progress';
import SCLogoShort from 'images/sc-logo-short.svg';

const useStyles = makeStyles((theme) => {
    return {
        layout: {
            // same layout as login view
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0 auto',
            minHeight: 'calc(100vh - 5rem)',
            width: '39rem',
        },
        paper: {
            alignItems: 'center',
            borderRadius: '0.8rem',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
        },
        progress: {
            alignItems: 'top',
            display: 'flex',
        },
        progressText: {
            paddingLeft: theme.spacing(1),
        },
        simCaptureLogo: {
            backgroundSize: 'contain',
            height: '4.5rem',
            marginBottom: theme.spacing(2),
        },
    };
});

type LoginProgressProps = {
    messageText: string;
};

const LoginProgress = (props: LoginProgressProps) => {
    const {messageText} = props;
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            <Paper className={classes.paper}>
                <img className={classes.simCaptureLogo} src={SCLogoShort} />
                <div className={classes.progress}>
                    <SCCircularProgress size='small' />
                    <Typography className={classes.progressText}>{messageText}</Typography>
                </div>
            </Paper>
        </div>
    );
};

export default LoginProgress;
