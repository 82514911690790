/**
 * todo add block defs for different errors
 * todo sort with 3 digit stuff at top
 * todo error if not above
 *
 * Error codes objects have an `errorCode` string which is a number representing an HTTP status code followed by an
 * optional postfix of 3 or more digits to uniquely identify the specific error. They also have a unique `errorName`
 * in UPPER_CASE_SNAKE_CASE that can be used as a unique key to retrieve a given error code object from the object
 * exported by this file.
 *
 * Many of the error codes are further subdivided into groups as follows:
 * - 400 errors:
 *   - 4001xx authentication
 *   - 4002xx users
 *   - 4003xx sessions
 *   - 4004xx scenarios
 *   - 4005xx courses
 *   - 4006xx scheduling
 *   - 4007xx reports
 *   - 4008xx evaluations
 *   - 4009xx evaluation templates
 *   - 40010xx exam flows
 *   - 40012xx center sign-in
 *   - 40013xx settings
 *   - 40014xx notifications
 *   - 40015xx ip video and audio feeds
 * - 404 errors:
 *   - 4041xx authentication
 *   - 4042xx users
 *   - 4043xx sessions
 *   - 4044xx scenarios
 *   - 4045xx courses
 * - 502 errors:
 *   - 5021xxx video conductor
 *
 */
const errorCodes = [
    {errorCode: '400', errorName: 'BAD_REQUEST'}, // generic 400 error (usually created by controller)
    {errorCode: '400002', errorName: 'BAD_REQUEST_VALIDATION'}, // 400 error created by route config validator e.g. Joi
    {errorCode: '400003', errorName: 'CANNOT_PARSE_JSON'},
    {errorCode: '400004', errorName: 'SQL_ACCESS_DENIED'},
    {errorCode: '400005', errorName: 'UPDATE_CONFLICT'},
    {errorCode: '400006', errorName: 'CLIENT_UPDATE_CONFLICT'},
    {errorCode: '400007', errorName: 'QUESTION_CATEGORIES_BAD_DATA'},
    {errorCode: '400008', errorName: 'BAD_MIME_TYPE'},
    {errorCode: '400009', errorName: 'REGISTER_SIMULATOR_FAILED'},
    {errorCode: '400010', errorName: 'MISSING_REQUIRED_FEATURE'},
    {errorCode: '400011', errorName: 'IMPORT_FAILED'},
    {errorCode: '400013', errorName: 'AVATAR_RESOLUTION_TOO_HIGH'},
    {errorCode: '400014', errorName: 'UNSUPPORTED_SOCKET_EVENT'},
    {errorCode: '400015', errorName: 'UNSUPPORTED_TIMEZONE'},
    {errorCode: '4001001', errorName: 'EXAM_CONFIGURATION_PROBLEM'},
    {errorCode: '4001002', errorName: 'EXAM_FLOW_USED_IN_ROOM'},
    {errorCode: '4001003', errorName: 'CANNOT_CREATE_DOOR_NOTE'},
    {errorCode: '4001004', errorName: 'INVALID_COURSE_STUDENT'},
    {errorCode: '4001005', errorName: 'CANNOT_LOGIN_EXAM_STARTED'},
    {errorCode: '4001006', errorName: 'INVALID_STUDENTS'},
    {errorCode: '4001008', errorName: 'LEARNER_ALREADY_IN_EXAM'},
    {errorCode: '4001009', errorName: 'LEARNER_ALREADY_IN_EXAM_ROOM'},
    {errorCode: '400101', errorName: 'LOGIN_ERROR'}, // generic login error e.g. user/password incorrect
    {errorCode: '4001010', errorName: 'PATIENT_ALREADY_IN_EXAM_ROOM'},
    {errorCode: '4001011', errorName: 'EXAM_REMOTE_STREAMING_PROBLEM'},
    {errorCode: '4001012', errorName: 'EXAM_REMOTE_RECORDING_PROBLEM'},
    {errorCode: '4001013', errorName: 'CANNOT_DELETE_HONOR_CODE'},
    {errorCode: '4001014', errorName: 'CANNOT_DELETE_AUDIO_MESSAGE'},
    {errorCode: '4001015', errorName: 'VERA_RECORDING_MAX_POLLING_RETRIES'},
    {errorCode: '400102', errorName: 'LOGIN_CHANGE_PASSWORD'}, // user must change or reset password
    {errorCode: '400103', errorName: 'LOGIN_INACTIVE'},
    {errorCode: '400104', errorName: 'LOGIN_LOCKOUT'},
    {errorCode: '400105', errorName: 'LOGIN_CONNECTION_ERROR'},
    {errorCode: '400106', errorName: 'BAD_PASSWORD'},
    {errorCode: '400107', errorName: 'MISMATCH_PASSWORD'},
    {errorCode: '400108', errorName: 'REPEAT_PASSWORD'},
    {errorCode: '400109', errorName: 'MULTIPLE_USER_MATCHES_ERROR'},
    {errorCode: '400110', errorName: 'MULTIPLE_CLIENTS_MATCHES_ERROR'},
    {errorCode: '4001101', errorName: 'VC_UNKNOWN_ERROR'},
    {errorCode: '4001102', errorName: 'VC_INVALID_TOKEN'},
    {errorCode: '4001103', errorName: 'MISMATCH_LOCATIONS'},
    {errorCode: '400111', errorName: 'LOGIN_NO_PASSWORD'},
    {errorCode: '4001202', errorName: 'ROUTE_53_FAILED_SYNC'},
    {errorCode: '4001206', errorName: 'CSI_USER_ALREADY_SIGNED_IN'},
    {errorCode: '4001207', errorName: 'CSI_USER_NOT_SIGNED_IN'},
    {errorCode: '4001208', errorName: 'CSI_CENTER_SIGN_IN_SETTING_NOT_FOUND'},
    {errorCode: '4001209', errorName: 'CSI_INCORRECT_PASSWORD'},
    {errorCode: '4001210', errorName: 'CSI_USER_NOT_FOUND'},
    {errorCode: '4001301', errorName: 'SETTINGS_BAD_IDP_URL'},
    {errorCode: '4001302', errorName: 'SETTINGS_BAD_IDP_METADATA'},
    {errorCode: '4001401', errorName: 'BAD_NOTIFICATION_MODE_REF'},
    {errorCode: '4001402', errorName: 'BAD_NOTIFICATION_TYPE_REF'},
    {errorCode: '4001501', errorName: 'NON_UNIQUE_IP_FEED_NAME'},
    {errorCode: '4001502', errorName: 'NON_UNIQUE_IP_FEED_IP_ADDRESS'},
    {errorCode: '4001600', errorName: 'PDL_VIDEO_NOT_RECORDED'},
    {errorCode: '4001601', errorName: 'APPLICATION_INVALID_TOKEN'},
    {errorCode: '4001700', errorName: 'VERA_INVALID_RESPONSE'},
    {errorCode: '4001800', errorName: 'PDL_MULTIDEVICE_SELF_JOIN'},
    {errorCode: '4001900', errorName: 'PDL_SESSION_VIDEO_EXISTS'},
    {errorCode: '4001901', errorName: 'PDL_SESSION_VIDEO_ALREADY_COMPLETED'},
    {errorCode: '4002000', errorName: 'INVALID_LTI_SETTINGS'},
    {errorCode: '400201', errorName: 'INVALID_USER'},
    {errorCode: '400202', errorName: 'USER_UPDATE_CONFLICT'},
    {errorCode: '400203', errorName: 'FAILED_TO_CREATE_LAERDAL_CONNECT_USER'},
    {errorCode: '400204', errorName: 'USER_IMPORT_TOO_MANY_USERS'},
    {errorCode: '400205', errorName: 'USER_IN_USE'},
    {errorCode: '400206', errorName: 'USER_PROTECTED'},
    {errorCode: '400207', errorName: 'USER_IN_SESSION'},
    {errorCode: '4002100', errorName: 'OPENSEARCH_APPLICATION_ERROR'},
    {errorCode: '400301', errorName: 'UNAUTHORIZED_SESSION_START_USER'},
    {errorCode: '400302', errorName: 'SPRITES_SIGNED_COOKIES_ERROR'},
    {errorCode: '400303', errorName: 'CALLER_NOT_LEARNER_OR_FACILITATOR'},
    {errorCode: '400304', errorName: 'USER_CANNOT_BE_LEARNER_AND_FACILITATOR'},
    {errorCode: '400305', errorName: 'FACILITATOR_NOT_PARTICIPANT'},
    {errorCode: '400306', errorName: 'NO_PARTICIPANTS'},
    {errorCode: '400307', errorName: 'LEARNER_NOT_PARTICIPANT'},
    {errorCode: '400308', errorName: 'FAILED_TO_CREATE_SESSION'},
    {errorCode: '400309', errorName: 'COURSE_ROLE_SESSION_PARTICIPANT_CONFLICT'},
    {errorCode: '400401', errorName: 'INVALID_QUESTION_TYPE_FOR_REFLECTION'},
    {errorCode: '400402', errorName: 'INVALID_EVALUATION_QUESTION'},
    {errorCode: '400404', errorName: 'EXAM_SCENARIO_CANNOT_UPDATE'},
    {errorCode: '400405', errorName: 'EMR_TEMPLATE_CANNOT_DELETE'},
    {errorCode: '400406', errorName: 'INVALID_ENROLLMENT_END_TS'},
    {errorCode: '400407', errorName: 'FEATURE_UNAVAILABLE'},

    // Client creation
    {errorCode: '4004200', errorName: 'CREATE_CLIENT_INVALID_SUBDOMAIN'},
    {errorCode: '4004201', errorName: 'CREATE_CLIENT_SUBDOMAIN_IN_USE'},
    {errorCode: '4004202', errorName: 'CREATE_CLIENT_CLIENT_EXISTS'},

    {errorCode: '400501', errorName: 'COURSE_UPDATE_CONFLICT'},
    {errorCode: '400502', errorName: 'INVALID_COURSE_UPDATE'},
    {errorCode: '400503', errorName: 'COURSE_STATUS_NOT_FOUND'},
    {errorCode: '400504', errorName: 'COURSE_ITEM_SORT_ERROR'},
    {errorCode: '400601', errorName: 'RESERVATION_UPDATE_CONFLICT'},
    {errorCode: '400602', errorName: 'RESERVATION_REQUEST_UPDATE_CONFLICT'},
    {errorCode: '400603', errorName: 'INVALID_RESERVATION_REQUEST_ID'},
    {errorCode: '400701', errorName: 'REPORTS_SERVICE_ERROR'},
    {errorCode: '400801', errorName: 'BAD_EVALUATION_TEMPLATE_DATA'},
    {errorCode: '400802', errorName: 'CANNOT_CREATE_DURING_SESSION_EVALUATION'},
    {errorCode: '400803', errorName: 'CANNOT_CREATE_REFLECTION_EVALUATION'},
    {errorCode: '400804', errorName: 'CANNOT_CREATE_BEFORE_SESSION_EVALUATION'},
    {errorCode: '400805', errorName: 'CANNOT_CREATE_AFTER_SESSION_EVALUATION'},
    {errorCode: '400806', errorName: 'CANNOT_CREATE_COURSE_EVALUATION'},
    {errorCode: '400807', errorName: 'BAD_EVALUATION_DATA'},
    {errorCode: '400808', errorName: 'CANNOT_CREATE_EXAM_EVALUATION'},
    {errorCode: '400809', errorName: 'CANNOT_CREATE_MONITOR_EVALUATION'},
    {errorCode: '400810', errorName: 'CANNOT_CREATE_SCORING_EVALUATION'},
    {errorCode: '400811', errorName: 'CANNOT_CREATE_PDL_EVALUATION'},
    {errorCode: '400812', errorName: 'EVALUATION_ALREADY_COMPLETED'},
    {errorCode: '400813', errorName: 'CANNOT_CREATE_EMR'},
    {errorCode: '400901', errorName: 'CANNOT_CREATE_EVALUATION_TEMPLATE'},
    {errorCode: '400902', errorName: 'IOS_APP_PARTICIPANT_LOGIN'},
    {errorCode: '400903', errorName: 'INVALID_SNS_MESSAGE'},
    {errorCode: '400904', errorName: 'SELF_RECORD_CACHE_UPDATE_FAILED'},
    {errorCode: '401', errorName: 'UNAUTHORIZED'},
    {errorCode: '401102', errorName: 'TOKEN_INVALID'},
    {errorCode: '401103', errorName: 'TOKEN_EXPIRED'},
    {errorCode: '401104', errorName: 'UNAUTHORIZED_USER_INACTIVE'},
    {errorCode: '401105', errorName: 'UNAUTHORIZED_USER'},
    {errorCode: '401106', errorName: 'MULTIPLE_USERS_LOGGED_IN'},
    {errorCode: '401107', errorName: 'CLIENT_INVALID'},
    {errorCode: '401108', errorName: 'AUTHORIZATION_FAULT'}, // a route handler failed to check authorization
    {errorCode: '401109', errorName: 'PASSWORD_EXPIRED'}, // users with expired passwords can only use certain endpoints to reset their password
    {errorCode: '403', errorName: 'FORBIDDEN'},
    {errorCode: '403000', errorName: 'TEAM_REPORTER_INVALID_SESSION'},
    {errorCode: '404', errorName: 'NOT_FOUND'},
    {errorCode: '404001', errorName: 'NOTIFICATION_NOT_FOUND'},
    {errorCode: '404002', errorName: 'LOCATION_NOT_FOUND'},
    {errorCode: '404003', errorName: 'SIMULATOR_NOT_FOUND'},
    {errorCode: '404004', errorName: 'ORGANIZATION_NOT_FOUND'},
    {errorCode: '404005', errorName: 'CAPTURE_HOST_NOT_FOUND'},
    {errorCode: '404006', errorName: 'AGENDA_ROLE_USERS_NOT_FOUND'},
    {errorCode: '404007', errorName: 'AGENDA_ROLES_NOT_FOUND'},
    {errorCode: '404008', errorName: 'CAPTURE_NODE_NOT_FOUND'},
    {errorCode: '404009', errorName: 'APPLICATION_CAPTUREHOST_NOT_AVAILABLE'},
    {errorCode: '404010', errorName: 'APPLICATION_CAPTURENODE_NOT_AVAILABLE'},
    {errorCode: '404011', errorName: 'RECORDING_ANNOTATION_NOT_FOUND'},
    {errorCode: '404012', errorName: 'RESERVATION_NOT_FOUND'},
    {errorCode: '4041007', errorName: 'DOOR_NOTE_NOT_FOUND'},
    {errorCode: '404101', errorName: 'LOGIN_CLIENT_NOT_FOUND'},
    {errorCode: '404102', errorName: 'CLIENT_NOT_FOUND'},
    {errorCode: '404201', errorName: 'USER_NOT_FOUND'},
    {errorCode: '404301', errorName: 'SESSION_NOT_FOUND'},
    {errorCode: '404302', errorName: 'SESSION_RECORDINGS_NOT_FOUND'},
    {errorCode: '404401', errorName: 'SCENARIO_NOT_FOUND'},
    {errorCode: '404402', errorName: 'SCENARIO_ITEM_NOT_FOUND'},
    {errorCode: '404403', errorName: 'EVALUATION_TEMPLATE_NOT_FOUND'},
    {errorCode: '404404', errorName: 'SCORING_RUBRIC_NOT_FOUND'},
    {errorCode: '404405', errorName: 'SCORER_ASSIGNMENT_NOT_FOUND'},
    {errorCode: '404406', errorName: 'EVALUATION_NOT_FOUND'},
    {errorCode: '404501', errorName: 'COURSE_NOT_FOUND'},
    {errorCode: '404502', errorName: 'COURSE_PASSING_CUTOFF_UNKNOWN'},
    {errorCode: '404601', errorName: 'RELEASE_NOT_FOUND'},
    {errorCode: '409', errorName: 'CONFLICT'},
    {errorCode: '409001', errorName: 'CAPTURE_NODE_EXISTS'},
    {errorCode: '4090101', errorName: 'DUPLICATE_AUDIO_MESSAGE'},
    {errorCode: '4090102', errorName: 'DUPLICATE_AUDIO_MODULE'},
    {errorCode: '4091701', errorName: 'VERA_CONFLICT'},
    {errorCode: '422', errorName: 'BAD_DATA'},
    {errorCode: '500', errorName: 'INTERNAL_SERVER_ERROR'},
    {errorCode: '500001', errorName: 'INTERNAL_SERVER_VALIDATION'},
    {errorCode: '500002', errorName: 'UNIMPLEMENTED_LEGACY_METHOD'},
    {errorCode: '500003', errorName: 'LTI_LINK_ERROR'},
    {errorCode: '502001', errorName: 'UNEXPECTED_SERVICE_ERROR'},
    {errorCode: '502002', errorName: 'CANNOT_REMOVE_FEEDS'},
    {errorCode: '5021401', errorName: 'VC_BAD_REQUEST'},
    {errorCode: '5021403', errorName: 'VC_FORBIDDEN'},
    {errorCode: '5021404', errorName: 'VC_NOT_FOUND'},
    {errorCode: '5021500', errorName: 'VC_UNKNOWN'},
    {errorCode: '5021501', errorName: 'VC_NOT_IMPLEMENTED'},
    {errorCode: '5021502', errorName: 'VC_BAD_GATEWAY'},
    {errorCode: '5021504', errorName: 'VC_GATEWAY_TIMEOUT'},
    {errorCode: '503', errorName: 'SERVICE_UNAVAILABLE'},
    {errorCode: '504', errorName: 'GATEWAY_TIMEOUT'},
    {errorCode: '520', errorName: 'UNKNOWN_ERROR'},
] as const;

export type ErrorCode = (typeof errorCodes)[number];
export type ErrorsLookup = {
    [K in ErrorCode['errorName']]: Extract<ErrorCode, {errorName: K}>;
};

const getErrorCodesByName = (): ErrorsLookup => {
    const errorCodesSet = new Set<ErrorCode['errorCode']>();
    const errorsMap = new Map<ErrorCode['errorName'], ErrorCode>();

    errorCodes.forEach((errorCode, index) => {
        if (errorCodesSet.has(errorCode.errorCode)) {
            throw new Error(`Duplicate error code: ${errorCode.errorCode}`);
        } else if (errorsMap.has(errorCode.errorName)) {
            throw new Error(`Duplicate error name: ${errorCode.errorName}`);
        } else if (index > 0 && errorCode.errorCode < errorCodes[index - 1].errorCode) {
            throw new Error(
                `Error code is out of order: ${errorCode.errorCode}. Please ensure all error codes are defined in ascending alphabetical order.`,
            );
        }

        errorCodesSet.add(errorCode.errorCode);
        errorsMap.set(errorCode.errorName, errorCode);
    });

    return Object.fromEntries(errorsMap) as ErrorsLookup;
};

export default getErrorCodesByName();
