/*
 * TODO: Rethink the entirety of our app initialization. It starts with connecting our reducers
 * and leads here to dispatching settings into the store and doing intl stuff. We probably don't
 * need and shouldn't get settings here.
 */
import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import * as hist from 'history';
require('webrtc-adapter'); // normalizes some WebRTC implementation differences between browsers

import isNil from 'lodash/isNil';

import AppRoutes from './app-routes';
import AppStore from 'stores/app-store';
import IntlStore from 'stores/intl-store';
import SessionStore from 'stores/session-store';

import {getSettings} from 'actions/session-actions';

import {connectReducer, initializeReduxStore} from 'redux-store';
import {LDProvider, asyncWithLDProvider} from 'launchdarkly-react-client-sdk';

// Constants
import * as EnvironmentConstants from './constants/environment-constants';

// Utils
import Logger from 'utils/logger';
import IntlUtils from 'utils/intl-utils';
import {configureCanvasJS} from 'utils/canvasjs-utils';

const logger = new Logger('main');
const isDatadogEnabled = __IS_DATADOG_ENABLED__;
const deployment = __DEPLOYMENT__;
const environment = deployment.includes('production') ? 'production' : deployment;

const browserHistory = hist.createBrowserHistory();

if (isNil(environment)) {
    throw new Error(`Unable to initialize DataDog or LaunchDarkly with environment: ${environment}`);
}

//#region DataDog
// Confluence: https://blinemedical.atlassian.net/wiki/spaces/SD/pages/3164078092
try {
    if (isDatadogEnabled) {
        let ddApplicationId;
        let ddClientToken;
        if (environment === 'production') {
            ddApplicationId = EnvironmentConstants.DATADOG_PRODUCTION_SIMCAPTURE_CLOUD_APPLICATION_ID;
            ddClientToken = EnvironmentConstants.DATADOG_PRODUCTION_SIMCAPTURE_CLOUD_CLIENT_TOKEN;
        } else {
            // All non-production deployments use the development application.
            ddApplicationId = EnvironmentConstants.DATADOG_DEVELOPMENT_SIMCAPTURE_CLOUD_APPLICATION_ID;
            ddClientToken = EnvironmentConstants.DATADOG_DEVELOMENT_SIMCAPTURE_CLOUD_CLIENT_TOKEN;
        }

        const datadogOptions = {
            clientToken: ddClientToken,
            env: environment,
            site: 'datadoghq.com',
            service: EnvironmentConstants.SERVICE_CONSUMERS.SIMCAPTURE_CLOUD,
            version: __VERSION__,
            sampleRate: 100,
        };

        datadogLogs.init({
            ...datadogOptions,
            forwardConsoleLogs: 'all',
        });

        datadogRum.init({
            ...datadogOptions,
            applicationId: ddApplicationId,
            trackInteractions: true,
            silentMultipleInit: true,
            allowedTracingUrls: [
                // Add datadog tracing headers to requests to simcapture API
                // so that we can tie rum sessions to apm traces
                /https:\/\/api\.dev\.simcapture-dev\.com.*/,
                /https:\/\/.*.fargate.simcapture-dev\.com.*/,
                /https:\/\/api.*\.simcapture-staging\.com.*/,
                /https:\/\/api.*\.simcapture\.com.*/,
            ],
            beforeSend: (event) => {
                event.context = {
                    ...event.context,
                    deployment,
                    ui_service: __UI_SERVICE__,
                };
            },
        });
        // Enable Session Replay
        datadogRum.startSessionReplayRecording();
    }
} catch (err) {
    logger.error(`Failed to initialize RUM monitoring for environment: ${environment}`, err);
}
//#endregion DataDog

// This logs the UI version to the console and is very useful for troubleshooting. Please do not delete it
// eslint-disable-next-line
console.log(__VERSION__);

//#region LaunchDarkly
const getLaunchDarklyContext = (simCaptureEnvironment) => {
    if (simCaptureEnvironment === 'local') {
        return {
            kind: 'user',
            key: __UI_LAUNCHDARKLY_CONTEXT_KEY__,
        };
    } else if (window.location.host.split('.')[1]) {
        return {
            kind: 'user',
            key: window.location.host.split('.')[0],
        };
    } else {
        throw new Error('Non-local environments must have a subdomain');
    }
};

const launchdarklyClientSideId = EnvironmentConstants.LAUNCH_DARKLY_CLIENT_IDS[environment];

if (isNil(launchdarklyClientSideId)) {
    throw new Error(`Unable to set LaunchDarkly clientSideID with environment: ${environment}`);
}

const launchdarklyProviderConfig = {
    clientSideID: launchdarklyClientSideId,
    context: getLaunchDarklyContext(environment),
    options: {},
};

const setupLDProvider = () => {
    return asyncWithLDProvider(launchdarklyProviderConfig);
};
//#endregion LaunchDarkly

const renderTheApplication = async (store) => {
    let LaunchDarklyProvider;
    try {
        LaunchDarklyProvider = await setupLDProvider();
    } catch (err) {
        logger.error('Failed to initialize asynchronous LDProvider', err);
        LaunchDarklyProvider = LDProvider;
    }

    ReactDOM.render(
        <div>
            <LaunchDarklyProvider {...launchdarklyProviderConfig}>
                <Provider store={store} key='provider'>
                    <ConnectedRouter history={browserHistory}>
                        <Switch>
                            <Route path='/'>
                                <AppRoutes />
                            </Route>
                        </Switch>
                    </ConnectedRouter>
                </Provider>
            </LaunchDarklyProvider>
        </div>,
        document.getElementById('root'),
    );
};

const handleFailure = async (store, error) => {
    // log error and render anyway
    console.error('handleFailure', error);
    await renderTheApplication(store);
};

// todo: polyfill intl cldr data if needed
const setupIntl = async (store) => {
    const settings = store.getState().session.settings;

    IntlUtils.updateTimeFormats(settings);
    await renderTheApplication(store);
};

try {
    connectReducer('app', AppStore);
    connectReducer('intl', IntlStore);
    connectReducer('session', SessionStore);

    const store = initializeReduxStore(browserHistory);
    configureCanvasJS();

    // Get application global settings ie: locale etc..
    store.dispatch(getSettings(setupIntl.bind(this, store), handleFailure.bind(this, store)));
} catch (err) {
    console.error(err);
    ReactDOM.render(
        <div>
            <h2> Error: Application could not load. </h2>
            <pre>
                <strong>{err.toString()}</strong>
                {!!err.stack && (
                    <div>
                        <br /> {err.stack}{' '}
                    </div>
                )}
            </pre>
        </div>,
        document.getElementById('root'),
    );

    throw err;
}
