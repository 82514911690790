import useDocumentTitle from 'hooks/use-document-title';

import type {ReactElement} from 'react';
import type {MessageDescriptor} from 'react-intl';

type Props = {
    title?: MessageDescriptor;
    children: ReactElement;
};

export default (props: Props) => {
    const {title, children} = props;
    useDocumentTitle(title);

    return children;
};
