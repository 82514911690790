Object.defineProperty(exports, '__esModule', {
    value: true,
});

exports.default = {
    authenticating: {
        defaultMessage: 'Authenticating',
        description: 'Login',
        id: 'authenticating.label',
    },
    back: {
        defaultMessage: 'Return to Login Page',
        id: 'back_button.label',
    },
    badPasswordError: {
        defaultMessage: 'The password you entered does not meet the requirements.',
        id: 'reset_password.bad_password',
    },
    clickHere: {
        defaultMessage: 'Click here',
        id: 'click_here',
    },
    client: {
        defaultMessage: 'Client Name',
        description: 'Login',
        id: 'client_input.label',
    },
    clientNotFound: {
        defaultMessage: 'The client could not be found.',
        id: 'errors.login_client_not_found',
    },
    copyright: {
        defaultMessage: '© Laerdal Medical. Confidential and proprietary. All rights reserved. Patented technology.',
        id: 'copyright',
    },
    email: {
        defaultMessage: 'Email',
        description: 'Login',
        id: 'email_input.label',
    },
    emailPlaceholder: {
        defaultMessage: 'Enter your account email',
        id: 'email_input.placeholder',
    },
    expiredHelpText: {
        defaultMessage: 'Please create a new password in order to continue.',
        id: 'invalid_password.helpText',
    },
    expiredResetTitle: {
        defaultMessage: 'Your password has been reset and needs to be changed',
        id: 'invalid_password.reset.title',
    },
    expiredTitle: {
        defaultMessage: 'Your password has expired',
        id: 'invalid_password.expired.title',
    },
    forgotPassword: {
        defaultMessage: 'Forgot Password?',
        id: 'forgot_button.label',
    },
    label: {
        defaultMessage: 'Username',
        id: 'forgot_password_input.label',
    },
    laerdalLoginText: {
        defaultMessage: 'Please click the button below to log in with Laerdal Connect.',
        id: 'laerdal_login.text',
    },
    login: {
        defaultMessage: 'Login',
        id: 'login_button.label',
    },
    loginError: {
        defaultMessage: 'The username or password you entered is incorrect.',
        id: 'errors.login_error',
    },
    loginErrorPassthrough: {
        defaultMessage: 'An error occurred with the passthrough login.',
        id: 'login.error.passthrough',
    },
    loginErrorPassthroughExpired: {
        defaultMessage: 'Passthrough link expired. Please generate a new one in jAUS.',
        id: 'login.error.passthrough_expired',
    },
    loginErrorSsoErrorOccurred: {
        defaultMessage: 'An error occurred. Please contact your administrator for assistance.',
        id: 'login.error.sso_error_occurred',
    },
    loginErrorSsoInvalidRelayState: {
        defaultMessage: 'Invalid SAML relay state.',
        id: 'login.error.sso_invalid_relay_state',
    },
    loginErrorSsoInvalidSsoExternalId: {
        defaultMessage:
            'Invalid Name ID received from IDP. Login settings for Single Sign-On may be misconfigured. Please contact your administrator for assistance.',
        id: 'login.error.sso_invalid_sso_external_id',
    },
    loginErrorSsoNotEnabled: {
        defaultMessage: 'Single Sign-On is not enabled. Please contact your administrator for assistance.',
        id: 'login.error.sso_not_enabled',
    },
    loginErrorSsoUnknownError: {
        defaultMessage: 'An unexpected error has occurred.',
        id: 'login.error.sso_unknown_error',
    },
    loginErrorSsoUserInactive: {
        defaultMessage: 'Your SimCapture account is inactive. Please contact your administrator for assistance.',
        id: 'login.error.sso_user_inactive',
    },
    loginErrorSsoUserNotFound: {
        defaultMessage: 'Your SimCapture account was not found. Please contact your administrator for assistance.',
        id: 'login.error.sso_user_not_found',
    },
    loginErrorTraceId: {
        defaultMessage: 'Error ID: {traceId}',
        id: 'login.error.trace_id',
    },
    loginInactive: {
        defaultMessage: 'Your account has been deactivated. Please contact an administrator.',
        id: 'errors.login_inactive',
    },
    loginLockout: {
        defaultMessage: 'You have been locked out due to excessive login attempts. Try again later.',
        id: 'errors.login_lockout',
    },
    password: {
        defaultMessage: 'Password',
        id: 'password_input.label',
    },
    passwordPlaceholder: {
        defaultMessage: 'Enter your SimCapture Password',
        id: 'password_input.placeholder',
    },
    placeholder: {
        defaultMessage: 'Enter your SimCapture Username',
        id: 'forgot_password_input.placeholder',
    },
    requestResetPasswordDescription: {
        defaultMessage:
            'To get a verification code, enter the email associated with your account. You will receive an email that will allow you to reset your password, which will expire after 24 hours.',
        id: 'request_reset_password.description',
    },
    requestResetPasswordDescriptionUniqueDomain: {
        defaultMessage:
            'To get a verification code, enter the email or username associated with your account. You will receive an email that will allow you to reset your password, which will expire after 24 hours.',
        id: 'request_reset_password.description.unique_domain',
    },
    requestResetPasswordTitle: {
        defaultMessage: 'Get verification email',
        id: 'request_reset_password.title',
    },
    resetError: {
        defaultMessage: 'Error occurred. Please try again later.',
        id: 'reset_password.error',
    },
    resetPasswordDescription: {
        defaultMessage: 'Enter a new password to regain access to your account.',
        id: 'reset_password.description',
    },
    resetPasswordFailed: {
        defaultMessage: 'Password reset failed. Please try again.',
        id: 'invalid_password.reset_passw',
    },
    resetPasswordIdentifierLabel: {
        defaultMessage: 'Enter email address',
        id: 'reset_password.identifier_label',
    },
    resetPasswordIdentifierLabelUniqueDomain: {
        defaultMessage: 'Enter username or email address',
        id: 'reset_password.identifier_label.unique_domain',
    },
    resetPasswordSentDescription: {
        defaultMessage:
            'Check your email. If a SimCapture account is associated with this email address, you will receive a message with instructions to reset your password.',
        id: 'reset_password.sent_description',
    },
    resetPasswordTitle: {
        defaultMessage: 'Enter in a new password',
        id: 'reset_password.title',
    },
    resetSuccessDescription: {
        defaultMessage: 'Your password has been reset. Return to login.',
        id: 'reset_password.reset_success_description',
    },
    resetSuccessDescriptionWithLink: {
        defaultMessage: 'Your password has been reset. {link} to return to login.',
        id: 'invalid_password.reset_success',
    },
    resetSuccessTitle: {
        defaultMessage: 'Success',
        id: 'reset_password.reset_success_title',
    },
    returnToLogin: {
        defaultMessage: 'Return to Login',
        id: 'invalid_password.returnToLogin',
    },
    send: {
        defaultMessage: 'Send',
        id: 'reset_password.send',
    },
    ssoLogin: {
        defaultMessage: 'SSO Login',
        id: 'login_button.sso.label',
    },
    success: {
        defaultMessage: 'An email for password reset has been sent to the email address on file',
        description: 'A message return to user when submitting a forgot password is successful',
        id: 'forgot_password_message.success',
    },
    titleForgotPassword: {
        defaultMessage: 'Retrieve Password',
        description: 'Forgot password title',
        id: 'forgot_password.title',
    },
    tokenInvalid: {
        defaultMessage: 'You have been logged out. Please login again to continue.',
        id: 'errors.token_invalid',
    },
    unknownError: {
        defaultMessage: 'Something went wrong. Please reload page and try again.',
        id: 'errors.login_connection_error',
    },
    username: {
        defaultMessage: 'Username',
        description: 'Login',
        id: 'username_input.label',
    },
    usernamePlaceholder: {
        defaultMessage: 'Enter your SimCapture Username',
        id: 'username_input.placeholder',
    },
};
