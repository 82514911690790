import isNil from 'lodash/isNil';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';

import ErrorBoundary from 'components/error-boundary/error-boundary';
import Page from 'components/page';
import LoadFailed from 'components/page-error/load-failed';
import ImpersonateUserSuccess from './views/impersonate-user-success';
import InvalidPassword from './views/invalid-password';
import Login from './views/login';
import LoginLaerdal from './views/login-laerdal';
import LoginSSOSuccess from './views/login-sso-success';
import Passthrough from './views/passthrough';
import RequestResetPassword from './views/request-reset-password';
import ResetPassword from './views/reset-password';

import MeteorCookies from 'utils/meteor-cookies';
import useIntlMessages from 'hooks/use-intl-messages';
import AnonymousMessages from 'intl/anonymous-messages';

import {connectReducer} from 'redux-store';
import ModalsStore from 'stores/modals-store';

import S from './anonymous.less';

connectReducer('modals', ModalsStore);

const LoginPath = '/login';
const LoginLaerdalPath = '/login/laerdal';
const PassthroughPath = '/passthrough';
const SsoPath = '/sso';
const ImpersonateUserSuccessPath = '/impersonate-user-success';
const ResetEmailPasswordPath = '/reset-password-email';
const RequestResetPasswordPath = '/request-reset-password';
const ExpiredPasswordPath = '/expired-password';
const ResetPasswordPath = '/reset-password';

const loginRoutes = [
    {
        path: LoginPath,
        title: AnonymousMessages.login,
        Component: Login,
    },
    {
        path: LoginLaerdalPath,
        title: AnonymousMessages.login,
        Component: LoginLaerdal,
    },
    {
        path: PassthroughPath,
        title: AnonymousMessages.login,
        Component: Passthrough,
    },
    {
        path: SsoPath,
        title: AnonymousMessages.login,
        Component: LoginSSOSuccess,
    },
];

const AnonymousRoutes = () => {
    const isLoaded = useIntlMessages('anonymous');
    // @ts-expect-error TS(2339) FIXME: Property 'modals' does not exist on type 'DefaultR... Remove this comment to see the full error message
    const modals = useSelector((state) => state.modals.modals);

    if (!isLoaded) {
        return null;
    }

    const cookies = MeteorCookies.getInstance();

    const isLoggedIn = !isNil(cookies.get('token'));

    return (
        <div className={S.main}>
            <ErrorBoundary customErrorHandler={LoadFailed}>
                <Switch>
                    {loginRoutes.map((route, k) => {
                        const {path, title, Component} = route;

                        if (isLoggedIn) {
                            return <Redirect key={k} to='/' path={path} exact />;
                        }

                        return (
                            <Route
                                key={k}
                                path={path}
                                exact
                                render={(routeProps) => (
                                    <Page title={title}>
                                        {/* @ts-expect-error TS(2322) FIXME: Type '{ history: History<unknown>; location: Locat... Remove this comment to see the full error message */}
                                        <Component {...routeProps} />
                                    </Page>
                                )}
                            />
                        );
                    })}
                    <Route path={ImpersonateUserSuccessPath} exact>
                        <Page title={AnonymousMessages.login}>
                            <ImpersonateUserSuccess />
                        </Page>
                    </Route>
                    <Route path={ResetEmailPasswordPath} exact>
                        <Page title={AnonymousMessages.titleForgotPassword}>
                            <ResetPassword />
                        </Page>
                    </Route>
                    <Route path={RequestResetPasswordPath} exact>
                        <Page title={AnonymousMessages.titleForgotPassword}>
                            <RequestResetPassword />
                        </Page>
                    </Route>
                    <Route
                        // TODO: MET-4435 expired-password requires a token to work. It isn't an anonymous route.
                        path={ExpiredPasswordPath}
                        exact
                        render={({location}) => (
                            <Page title={AnonymousMessages.expiredTitle}>
                                <InvalidPassword location={location} />
                            </Page>
                        )}
                    />
                    <Route
                        path={ResetPasswordPath}
                        exact
                        render={({location}) => (
                            <Page title={AnonymousMessages.expiredResetTitle}>
                                <InvalidPassword location={location} />
                            </Page>
                        )}
                    />
                </Switch>
                <div className={S.modalsLayer}>
                    {modals.toArray().map((modal, k) => {
                        return (
                            <div className={S.fill} key={k}>
                                {modal}
                            </div>
                        );
                    })}
                </div>
            </ErrorBoundary>
            <footer role='contentinfo' className={S.footer} title={`SimCapture v${__VERSION__}`}>
                <p>
                    <FormattedMessage {...AnonymousMessages.copyright} />
                </p>
            </footer>
        </div>
    );
};

export default AnonymousRoutes;

export const anonymousRoutePaths = [
    LoginPath,
    LoginLaerdalPath,
    PassthroughPath,
    SsoPath,
    ImpersonateUserSuccessPath,
    ResetEmailPasswordPath,
    RequestResetPasswordPath,
    ExpiredPasswordPath,
    ResetPasswordPath,
];
