const MESSAGING_NAMESPACE = '/messaging';
const uuidRegex = `[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}`;

export const getClientMessagingNamespace = (clientId: string) => `${MESSAGING_NAMESPACE}/${clientId}`;
export const getMessagingDynamicNamespace = () => new RegExp(`${MESSAGING_NAMESPACE}/${uuidRegex}`);

const IO_NAMESPACES = Object.freeze({
    DEFAULT: '/',
    EXAMS: '/exams',
    MESSAGING: `${MESSAGING_NAMESPACE}/${uuidRegex}`,
    SCMR: '/scmr'
});

export const validSocketNamespace = (namespace: string) => {
    return Object.values(IO_NAMESPACES).some((nsp) => {
        if (nsp === namespace || new RegExp(nsp).test(namespace)) {
            return true;
        }
        return false;
    });
};

export {IO_NAMESPACES};
