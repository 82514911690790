// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/typography.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/form.less");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
exports.push([module.id, ".form-errors__li___1dq0b {\n  list-style-type: disc;\n  margin-left: 2rem;\n}\n.form-errors__container___2liS4 {\n  margin-top: 0.5rem;\n}\n.form-errors__text___10dUb {\n}\n", ""]);
// Exports
exports.locals = {
	"li": "form-errors__li___1dq0b " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["small"] + "",
	"container": "form-errors__container___2liS4 " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["errorMessage"] + "",
	"text": "form-errors__text___10dUb " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["small"] + ""
};
module.exports = exports;
