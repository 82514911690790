// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/form.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
exports.push([module.id, ".form-label__label___1eeVY {\n}\n.form-label__textDisabled___JYP9x {\n}\n.form-label__requiredAsterisk___1BAWp {\n}\n.form-label__requiredAsteriskDisabled___1YqVR {\n}\n", ""]);
// Exports
exports.locals = {
	"label": "form-label__label___1eeVY " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label"] + "",
	"textDisabled": "form-label__textDisabled___JYP9x " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["textGrayLight"] + "",
	"requiredAsterisk": "form-label__requiredAsterisk___1BAWp " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["error"] + "",
	"requiredAsteriskDisabled": "form-label__requiredAsteriskDisabled___1YqVR " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["textGrayLight"] + ""
};
module.exports = exports;
