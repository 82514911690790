/*
 --------------------------------------------------
 --------------------------------------------------
     Table Name : LU_UserGroupTypes
 --------------------------------------------------
 --------------------------------------------------
 DO NOT MODIFY MANUALLY
 --------------------------------------------------
 -------------------------------------------------- */

export const ADMINISTRATOR = 'Administrator' as const;
export const COURSE_CONTRIBUTOR = 'Course_Contributor' as const;
export const COURSE_MONITOR = 'Course_Monitor' as const;
export const COURSE_PARTICIPANT = 'Course_Participant' as const;
export const COURSE_PATIENT = 'Course_Patient' as const;
export const COURSE_VIEWER = 'Course_Viewer' as const;
export const PARTICIPANT = 'Participant' as const;
export const SCENARIO_CONTRIBUTOR = 'Scenario_Contributor' as const;
export const SCENARIO_PATIENT = 'Scenario_Patient' as const;
export const SCENARIO_VIEWER = 'Scenario_Viewer' as const;
export const SESSION_CONTRIBUTOR = 'Session_Contributor' as const;
export const SESSION_PARTICIPANT = 'Session_Participant' as const;
export const SESSION_PATIENT = 'Session_Patient' as const;
export const SESSION_VIEWER = 'Session_Viewer' as const;
export const SYSTEM_ADMIN = 'SystemAdmin' as const;

const LU_UserGroupTypes = {
    ADMINISTRATOR,
    COURSE_CONTRIBUTOR,
    COURSE_MONITOR,
    COURSE_PARTICIPANT,
    COURSE_PATIENT,
    COURSE_VIEWER,
    PARTICIPANT,
    SCENARIO_CONTRIBUTOR,
    SCENARIO_PATIENT,
    SCENARIO_VIEWER,
    SESSION_CONTRIBUTOR,
    SESSION_PARTICIPANT,
    SESSION_PATIENT,
    SESSION_VIEWER,
    SYSTEM_ADMIN
} as const;

export default LU_UserGroupTypes;
