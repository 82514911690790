// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/layout.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/typography.less");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
// Module
exports.push([module.id, ".anonymous__main___1UFkJ {\n}\n.anonymous__modalsLayer___3jSkb {\n  isolation: isolate;\n  z-index: 2;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  pointer-events: none;\n}\n.anonymous__fill___2PJYn {\n  width: 100%;\n  height: 100%;\n}\n.anonymous__footer___2uZTP {\n}\n", ""]);
// Exports
exports.locals = {
	"main": "anonymous__main___1UFkJ " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["fillHeight"] + "",
	"modalsLayer": "anonymous__modalsLayer___3jSkb",
	"fill": "anonymous__fill___2PJYn",
	"footer": "anonymous__footer___2uZTP " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["minWidth"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["footerHeight"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["center"] + " " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["mediumGray"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["small"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["center"] + ""
};
module.exports = exports;
