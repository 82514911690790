import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import forEach from 'lodash/forEach';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';

import {setMessages, updateFormats, updateMessages} from 'actions/intl-actions';
import {importLocaleMessagesFile} from 'middleware/intl';
import {store} from 'redux-store';

import type {DateTimeFormatOptions} from 'intl';
import type {LocaleModule} from 'constants/intl-constants';

/**
 * Given one more more UI module names, loads the corresponding JSON file containing translations for locale messages
 * pertaining to that module. Messages are merged into the intl store. Any modules already loaded are skipped.
 *
 * @param moduleNames
 */
const ensureLocaleMessages = async (smartlingEnabled: boolean, ...moduleNames: LocaleModule[]): Promise<void> => {
    const {modules} = store.getState().intl;
    const locale = store.getState().session.settings.get('locale');
    const loadedModuleNames = Object.keys(modules);
    const unloadedModuleNames = difference(moduleNames, loadedModuleNames);

    const importedMessages = await Promise.all(
        unloadedModuleNames.map((moduleName) => importLocaleMessagesFile(locale, moduleName, smartlingEnabled)),
    );
    const mergedImportedMessages = merge({}, ...importedMessages);
    store.dispatch(setMessages(unloadedModuleNames, mergedImportedMessages));
};

const updateLocaleReloadAll = (callback, smartlingEnabled = false) => {
    const md = store.getState().intl.modules;

    let counter = 0;
    const failFn = () => {
        counter++;
        if (counter === md.length) {
            callback();
        }
    };

    if (md.length === 0) {
        callback();
        return;
    }

    forEach(md, (m) => {
        store.dispatch(updateMessages(m, failFn, failFn, smartlingEnabled));
    });
};

/**
 * Most browsers as of 2016 don't support setting the `timeZone` to anything
 * other than 'UTC'. Any other value throws an exception. It is also
 * possible that the server may send an invalid timezone setting for
 * whatever reason. As a fallback in these cases, we must use the last known
 * good timezone setting (i.e. not update the `timeZone` property).
 *
 * Note that by default we do not specify the value of the property, which
 * leads to the local browser time being used, which should work in all
 * browsers. Note also that this is NOT equivalent to specifying the local
 * timezone explicitly as the `timeZone` property, which will not work in
 * most browsers.
 */
const updateTimeFormats = function (settings) {
    const formats = store.getState().intl.formats;
    const updated = cloneDeep(formats);

    let tzInvalid = false;
    const testDate = new Date('2016-01-02T03:04:05Z');
    const testOptions: Partial<DateTimeFormatOptions> = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
        timeZone: settings.get('timezone'),
    };

    try {
        const dtf = new Intl.DateTimeFormat('en-US', testOptions);
        dtf.format(testDate);
    } catch (error) {
        tzInvalid = true;
    }

    if (!isUndefined(updated.date) && !isNull(updated.date)) {
        forEach(updated.date, (f) => {
            if (!tzInvalid) {
                f.timeZone = settings.get('timezone');
            }
            f.hour12 = settings.get('hourTwelve');
        });
    }
    store.dispatch(updateFormats(updated));
};

export default {
    ensureLocaleMessages,
    updateLocaleReloadAll,
    updateTimeFormats,
};
