/* eslint-disable no-console */
import moment from 'moment-timezone';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const DEFAULT_SOURCES = [
    'exam.control',
    'live-media-uploader',
    'sfu-peer-manager',
    'sfu-socket-api',
    'web-rtc-video',
    'web-rtc-paging',
    'lms-link-course',
    'lms-link-course-confirm-existing',
    'lms-link-course-confirm-new',
    'lms-link-course-success',
    'lms-module',
    'lms-integration-routes',
];

/**
 * General purpose logger for the UI that can send output to the browser console.
 * Each instance is associated with a "source", such as a
 * file or module, to assist with readability, searching, and filtering.
 *
 * Logging sources are configured at runtime in the the browser via the key
 * `meteor:ui-events:console:sources` in local storage. You can use the Logger
 * instance much like the native `console`, via the log level methods `debug`,
 * `error`, `info`, and `warn`. If the source of the log message matches one of
 * the enabled sources, the log message will be logged.
 *
 * The console transport is always on and will log every message received by the event logger.
 */
export default class Logger {
    constructor(source) {
        if (isNil(source)) {
            throw new Error('Missing one or more required parameters');
        }

        this.source = source;
    }

    // checks at runtime whether this source is permitted to log
    enabled() {
        const enabledSourcesStr = window.localStorage.getItem('meteor:ui-events:console:sources');
        const enabledSources = isEmpty(enabledSourcesStr) ? DEFAULT_SOURCES : JSON.parse(enabledSourcesStr);
        return enabledSources.includes(this.source);
    }

    prefix() {
        return `${moment.utc().toISOString()} ${this.source}:`;
    }

    // The following functions wrap the browser's `console` so we can decide at
    // runtime whether a given source is permitted to log or not, as well as
    // avoid eslint warnings for logging.

    debug(...args) {
        if (!this.enabled()) {
            return;
        }

        if (isNil(console.debug)) {
            console.info(this.prefix(), ...args); // some browsers lack console.debug
        } else {
            console.debug(this.prefix(), ...args);
        }
    }

    // logging to backend is async but we don't need to await it here
    error(...args) {
        if (!this.enabled()) {
            return;
        }

        console.error(this.prefix(), ...args);
    }

    info(...args) {
        if (!this.enabled()) {
            return;
        }

        console.info(this.prefix(), ...args);
    }

    warn(...args) {
        if (!this.enabled()) {
            return;
        }

        console.warn(this.prefix(), ...args);
    }
}
