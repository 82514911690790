import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyles = makeStyles(() => ({
    inProgressIcon: {
        animation: `$rotation 2000ms linear infinite`,
    },
    '@keyframes rotation': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
}));

const InProgressIcon = () => {
    const classes = useStyles();
    return <AutorenewIcon className={classes.inProgressIcon} />;
};

export default React.memo(InProgressIcon);
