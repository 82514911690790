import SocketIOEvents from 'shared-socket-io/socket-io-events';
import * as SocketIO from '../socket-io';

/**
 * Sends a websocket messageEvent to simcapture to broadcast a messageEvent to all users in a room
 *
 * @param {import('socket-io').Socket} socket
 * @param {import('shared-socket-io/socket-io-events').MESSAGING_EVENT_SP_SEND_MESSAGE |
 *          import('shared-socket-io/socket-io-events').MESSAGING_EVENT_MONITOR_SEND_MESSAGE} event
 * @param {string} roomId The room to send the message to
 * @param {object} messageEvent
 */
const sendChatMessage = (socket, event, roomId, messageEvent) => {
    return SocketIO.emit(
        event,
        {
            roomId,
            messageEvent,
        },
        socket,
    );
};

export const receiveMessages = (socket, callback) => {
    SocketIO.on(SocketIOEvents.MESSAGING_EVENT_RECEIVE_MESSAGES, callback, socket);
};

export const markMessagesRead = (socket, messageIds) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_READ_MESSAGES, {messageIds}, socket);
};

export const connectMonitorChat = (socket) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_MONITOR_CONNECT, undefined, socket);
};

export const connectSpChat = (socket, roomId, lastChatUpdate) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_SP_ENTER_ROOM, {roomId, lastChatUpdate}, socket);
};

export const monitorOpenRoomChat = (socket, roomId, lastChatUpdate) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_MONITOR_ENTER_ROOM, {roomId, lastChatUpdate}, socket);
};

export const monitorCloseRoomChat = (socket, roomId) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_MONITOR_LEAVE_ROOM, {roomId}, socket);
};

export const sendSpChat = (socket, roomId, messageEvent) => {
    return sendChatMessage(socket, SocketIOEvents.MESSAGING_EVENT_SP_SEND_MESSAGE, roomId, messageEvent);
};

export const sendMonitorChat = (socket, roomId, messageEvent) => {
    return sendChatMessage(socket, SocketIOEvents.MESSAGING_EVENT_MONITOR_SEND_MESSAGE, roomId, messageEvent);
};

/**
 * As a remote OSCE exam participant (learner or SP), enters the waiting room for any exam they are configured to be in.
 */
export const enterRemoteExamWaitingRoom = async (socket) => {
    return socket.emitWithAck(SocketIOEvents.EXAMS_ENTER_REMOTE_EXAM_WAITING_ROOM);
};
