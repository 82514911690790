import * as Immutable from 'immutable';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

// Wraps a function `trier` with a `try`/`catch`. If an error is caught,
// executes the `catcher`. By default, the `catcher` logs the error to the
// console. Useful for catching errors thrown by e.g. render props.
//
// Based on [ramda.tryCatch](http://ramdajs.com/docs/#tryCatch). This version is
// simpler so it may not work with functions that do fancy things with args, but
// should work for the majority of cases.
export function tryCatch(trier, catcher = logCaughtError) {
    return (...args) => {
        let result;

        try {
            result = trier(...args);
        } catch (err) {
            catcher(err);
        }

        return result;
    };
}

function logCaughtError(err) {
    console.error(err);
}

/**
 * Only tries to set the state if the component is mounted. Useful for
 * callbacks that get called after the component unmounts.
 *
 * NOTE: YOU MUST ADD this.mounted TO THE MOUNT/UNMOUNT OF THE COMPONENT YOU INTEND TO USE THIS ON
 */
export const setStateSafe = function (state, postStateChangeCallback) {
    if (isNil(this.mounted)) {
        throw new Error('When calling setStateSafe(), "this.mounted" should be defined.');
    }

    if (this.mounted) {
        let result;

        try {
            this.setState(state, postStateChangeCallback);
        } catch (err) {
            console.error('An error occurred while updating state: ', err);
        }

        return result;
    }
};

/**
 * Determines if any of the values of the props denoted by `keys` has changed.
 * Uses `Immutable.is` for instances of `Immutable.Collection` such as `List` and
 * `Map`. Uses `!==` otherwise.
 */
export const valuesChanged = function (prev, next, keys) {
    return (isNil(keys) ? Object.keys(next) : keys).some((key) => {
        const prevValue = prev[key];
        const nextValue = next[key];
        let propChanged = false;

        if (Immutable.isImmutable(prevValue)) {
            propChanged = !Immutable.is(prevValue, nextValue);
        } else {
            propChanged = prevValue !== nextValue;
        }
        return propChanged;
    });
};

export const propsChanged = valuesChanged;

const toImmutableSanitized = (value) => {
    return Immutable.fromJS(omitBy(value, isFunction));
};

export const shouldUpdate = (component, nextProps, nextState = null, nextContext = null) => {
    let update = !Immutable.is(toImmutableSanitized(nextProps), toImmutableSanitized(component.props));

    if (!isNil(nextContext)) {
        update = update || !Immutable.is(toImmutableSanitized(nextContext), toImmutableSanitized(component.context));
    }

    if (!isNil(nextState)) {
        update = update || !Immutable.is(toImmutableSanitized(nextState), toImmutableSanitized(component.state));
    }
    return update;
};
