import React from 'react';
import {useIntl} from 'react-intl';

import PageError from 'components/page-error/page-error';

import ErrorGraphic from 'images/error-graphic.svg';

import globalMessages from 'intl/global-messages';

import type {ReactNode} from 'react';

type LoadFailedProps = {error?: unknown; message?: ReactNode};

const LoadFailed = (props: LoadFailedProps) => {
    const {formatMessage} = useIntl();

    const onClick = () => {
        window.location.reload();
    };

    return (
        <PageError
            rumLabel='load_failed'
            line1={formatMessage(globalMessages.loadingError)}
            line2={formatMessage(globalMessages.pleaseReload)}
            imageSource={ErrorGraphic}
            buttonLabel={formatMessage(globalMessages.reloadPage)}
            buttonOnClick={onClick}
            error={props.error}
        />
    );
};

export default LoadFailed;
