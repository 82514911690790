import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';

export const parseJsonOrReturnString = (str) => {
    let result;
    try {
        result = JSON.parse(str);
    } catch (e) {
        if (e instanceof SyntaxError) {
            result = str;
        } else {
            result = e;
        }
    }
    return result;
};

export const leadDecimal = (num) => {
    let newNum = num.toString();
    if (newNum.length === 1) {
        newNum = '0' + newNum;
    }
    return newNum;
};

// For fields which can allow null or strings, return empty string instead of null.
// The purpose of this method is to prevent null values in form elements, which will throw a ReactJS warning.
export const returnEmptyIfNull = (obj, keys) => {
    keys.forEach((key) => {
        if (isNull(obj.get(key))) {
            obj = obj.set(key, '');
        }
    });
    return obj;
};

export const returnNullIfEmptyString = (str) => {
    if (!isNil(str) && str.trim() === '') {
        str = null;
    }
    return str;
};
