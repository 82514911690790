export const CSI_UNAUTHORIZED_REGISTRATION = 'csi-unauthorized-registration' as const;
export const CSI_USER_ALREADY_SIGNED_IN = 'csi-user-already-signed-in' as const;
export const CSI_USER_NOT_SIGNED_IN = 'csi-user-not-signed-in' as const;
export const EXAM_UNAUTHORIZED_LEARNER_TERMINAL_USER = 'exam-unauthorized-learner-terminal-user' as const;
export const INVALID_RELAY_STATE = 'invalid-relay-state' as const;
export const INVALID_SSO_EXTERNAL_ID = 'invalid-sso-external-id' as const;
export const MISSING_RELAY_STATE = 'missing-relay-state' as const;
export const SSO_NOT_ENABLED = 'sso-not-enabled' as const;
export const USER_INACTIVE = 'user-inactive' as const;
export const USER_NOT_FOUND = 'user-not-found' as const;
export const UNKNOWN_ERROR = 'unknown-error' as const;

export default {
    CSI_UNAUTHORIZED_REGISTRATION,
    CSI_USER_ALREADY_SIGNED_IN,
    CSI_USER_NOT_SIGNED_IN,
    EXAM_UNAUTHORIZED_LEARNER_TERMINAL_USER,
    INVALID_RELAY_STATE,
    INVALID_SSO_EXTERNAL_ID,
    MISSING_RELAY_STATE,
    SSO_NOT_ENABLED,
    USER_INACTIVE,
    USER_NOT_FOUND,
    UNKNOWN_ERROR
} as const;
