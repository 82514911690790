// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../../node_modules/less-loader/dist/cjs.js!styles/effects.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../../node_modules/less-loader/dist/cjs.js!styles/typography.less");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
// Module
exports.push([module.id, ".pass-through-login__container___jhFes {\n  width: 20rem;\n  position: absolute;\n  right: 0;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.pass-through-login__title___nW_-w {\n}\n.pass-through-login__client___i6hkP {\n  list-style-type: decimal;\n  margin-left: 2rem;\n}\n.pass-through-login__clientName___16sUO {\n}\n.pass-through-login__hasPdl___1CEa4 {\n  margin-left: 0.5rem;\n  padding: 0.1rem 0.5rem;\n  border-radius: 0.5rem;\n}\n.pass-through-login__instructions___FFloV {\n  margin-top: 1rem;\n}\n.pass-through-login__instruction___1XAp5 {\n  list-style-type: disc;\n  margin-left: 2rem;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "pass-through-login__container___jhFes " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["black"] + "",
	"title": "pass-through-login__title___nW_-w " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["normal"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["uppercase"] + "",
	"client": "pass-through-login__client___i6hkP " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["normal"] + "",
	"clientName": "pass-through-login__clientName___16sUO " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["hoverUnderline"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["hoverBrandBlue"] + " " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["brandBlue"] + "",
	"hasPdl": "pass-through-login__hasPdl___1CEa4 " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["white"] + " " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["darkGrayBG"] + "",
	"instructions": "pass-through-login__instructions___FFloV " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["small"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["uppercase"] + "",
	"instruction": "pass-through-login__instruction___1XAp5 " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["small"] + ""
};
module.exports = exports;
