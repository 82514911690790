export const MESSAGES_REQUEST = 'MESSAGES_REQUEST';
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const UPDATE_FORMATS = 'UPDATE_FORMATS';

export type LocaleModule =
    | 'accessibility'
    | 'activity'
    | 'anonymous'
    | 'authorized-anonymous'
    | 'center-sign-in'
    | 'chat-room'
    | 'cluster'
    | 'comet'
    | 'courses'
    | 'csi-kiosk'
    | 'dashboard'
    | 'evaluations'
    | 'global'
    | 'lleap-generated'
    | 'lleap'
    | 'lms'
    | 'paging'
    | 'participant-reports'
    | 'participant-results'
    | 'profile'
    | 'remote-exams'
    | 'reports'
    | 'scenarios'
    | 'schedule'
    | 'sessions'
    | 'system-setup'
    | 'users';
