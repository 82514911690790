const JSONUtils = {};

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;

JSONUtils.parse = function (json) {
    try {
        return JSON.parse(json);
    } catch (e) {
        // original error thrown has no error message so rethrow with message
        return json;
    }
};

JSONUtils.parseWithDate = function (json) {
    /// <summary>
    /// parses a JSON string and turns ISO or MSAJAX date strings
    /// into native JS date objects
    /// </summary>
    /// <param name="json" type="var">json with dates to parse</param>
    /// </param>
    /// <returns type="value, array or object" />
    try {
        return JSON.parse(json, function (key, value) {
            if (typeof value === 'string') {
                let a = reISO.exec(value);
                if (a) {
                    return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
                }
                a = reMsAjax.exec(value);
                if (a) {
                    const b = a[1].split(/[-+,.]/);
                    return new Date(b[0] ? +b[0] : 0 - +b[1]);
                }
            }

            if (value && typeof value === 'object' && Object.prototype.toString.apply(value) !== '[object Array]') {
                const replacement = {};
                for (const k in value) {
                    if (Object.hasOwnProperty.call(value, k)) {
                        replacement[k && k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
                    }
                }
                return replacement;
            }
            return value;
        });
    } catch (e) {
        // original error thrown has no error message so rethrow with message
        return json;
    }
};

JSONUtils.dateStringToDate = function (dtString) {
    /// <summary>
    /// Converts a JSON ISO or MSAJAX string into a date object
    /// </summary>
    /// <param name="" type="var">Date String</param>
    /// <returns type="date or null if invalid" />
    let a = reISO.exec(dtString);
    if (a) {
        return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
    }
    a = reMsAjax.exec(dtString);
    if (a) {
        const b = a[1].split(/[-,.]/);
        return new Date(+b[0]);
    }
    return null;
};
JSONUtils.stringifyWcf = function (json) {
    /// <summary>
    /// Wcf specific stringify that encodes dates in the
    /// a WCF compatible format ("/Date(9991231231)/")
    /// Note: this format works ONLY with WCF.
    ///       ASMX can use ISO dates as of .NET 3.5 SP1
    /// </summary>
    /// <param name="key" type="var">property name</param>
    /// <param name="value" type="var">value of the property</param>
    return JSON.stringify(json, function (key, value) {
        if (typeof value === 'string') {
            const a = reISO.exec(value);
            if (a) {
                const val =
                    '/Date(' + new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6])).getTime() + ')/';
                this[key] = val;
                return val;
            }
        }
        return value;
    });
};

export default JSONUtils;
