import {CALL_API} from 'middleware/api';
import * as constants from 'constants/session-constants';

export function fetchLookups(success, failure) {
    return (dispatch) => {
        return dispatch(serverFetchLookups(success, failure));
    };
}

export function getSettings(success, failure) {
    return (dispatch) => {
        return dispatch(serverFetchSettings(success, failure));
    };
}

export function updateSettings(data, success, failure) {
    return (dispatch) => {
        return dispatch(serverUpdateSettings(data, success, failure));
    };
}

export function authenticateUser(data, success, failure) {
    return (dispatch) => {
        return dispatch(serverAuthenticateUser(data, success, failure));
    };
}

export function userLogout(success, failure) {
    return (dispatch) => {
        return dispatch(serverUserLogout(success, failure));
    };
}

export function disposeStoresForMultipleUsers() {
    return {
        type: constants.DISPOSE_STORES_FOR_MULTIPLE_USERS,
    };
}

export function getSsoLoginUrl(success, failure) {
    return (dispatch) => {
        return dispatch(serverGetSsoLoginUrl(success, failure));
    };
}

export function redispatchAction(action) {
    return (dispatch) => {
        return dispatch(action);
    };
}

export function dismissUnAuthorized() {
    return {
        type: constants.DISMISS_UNAUTHORIZED,
    };
}

export function dismissUserFromRecordingStart() {
    return {
        type: constants.DISMISS_FROM_RECORDING_START,
    };
}

export function authenticateSession(status) {
    return {
        type: constants.AUTHENTICATE_SESSION,
        status: status,
    };
}

export function registerSessionUser(user) {
    return {
        type: constants.REGISTER_SESSION_USER,
        user: user,
    };
}

export function saveNextLocation(data) {
    return {
        type: constants.UPDATE_NEXT_LOCATION,
        data,
    };
}

export function dismissStatusWarning(statusType) {
    return {
        type: constants.DISMISS_STATUS_WARNING,
        statusType: statusType,
    };
}

export function setUserPermissions(userPermissions) {
    return {
        type: constants.USER_PERMISSIONS_SUCCESS,
        data: userPermissions,
    };
}

export function setUserPreferences(userPreferences) {
    return {
        type: constants.FETCH_USER_PREFERENCES_SUCCESS,
        data: userPreferences,
    };
}

export function setUserProfile(userProfile) {
    return {
        type: constants.USER_PROFILE_SUCCESS,
        data: userProfile,
    };
}

export function setUserSessionData(settings, userProfile, userPermissions, userPreferences) {
    return {
        type: constants.SET_USER_SESSION_DATA,
        data: {
            settings,
            userPermissions,
            userPreferences,
            userProfile,
        },
    };
}

export function clearSessionData() {
    return {
        type: constants.LOGOUT_USER_SUCCESS,
    };
}

export function fetchUserProfile(success, failure) {
    return (dispatch) => {
        return dispatch(serverFetchUserProfile(success, failure));
    };
}

export function fetchUserPermissions(success, failure) {
    return (dispatch) => {
        return dispatch(serverFetchUserPermissions(success, failure));
    };
}

export function fetchUserPreferences(success, failure) {
    return (dispatch) => {
        return dispatch(serverFetchUserPreferences(success, failure));
    };
}

export function sendNotifications(recipients, notification, success, failure) {
    return (dispatch) => {
        return dispatch(serverSendNotifications(recipients, notification, success, failure));
    };
}

export function updateProfileContact(updateCommand, successFn, failureFn) {
    return (dispatch) => {
        return dispatch(serverUpdateProfileContact(updateCommand, successFn, failureFn));
    };
}

export function updateProfilePassword(updateCommand, successFn, failureFn) {
    return (dispatch) => {
        return dispatch(serverUpdateProfilePassword(updateCommand, successFn, failureFn));
    };
}

export function uploadProfileAvatar(file, success, failure, onUploadProgress) {
    return (dispatch) => {
        return dispatch(serverUploadAvatar(file, success, failure, onUploadProgress));
    };
}

export function disposeSessionStore(data) {
    return {
        data,
        type: constants.DISPOSE_SESSION_STORE,
    };
}

export function disposePasswordStore(successFn) {
    return {
        type: constants.DISPOSE_PASSWORD_STORE,
    };
}

export function sessionIdlingInit(replace) {
    return {
        type: constants.SESSION_IDLING_INIT,
        replace,
    };
}

export function sessionIdlingDeactivate(options, success, failure) {
    return {
        type: constants.SESSION_IDLING_DEACTIVATE,
    };
}

export function sessionIdlingReset(success, failure) {
    return {
        type: constants.SESSION_IDLING_RESET,
    };
}

export function sessionIdlingWarn(success, failure) {
    return {
        type: constants.SESSION_IDLING_WARN,
    };
}

export function checkUserName(userName, success, failure) {
    return (dispatch) => {
        return dispatch(serverCheckUserName(userName, success, failure));
    };
}

export function checkEmail(userId, email, success, failure) {
    return (dispatch) => {
        return dispatch(serverCheckEmail(userId, email, success, failure));
    };
}

export function updateUserPreferences(updateCommand, successFn, failureFn) {
    return (dispatch) => {
        return dispatch(serverUpdateUserPreferences(updateCommand, successFn, failureFn));
    };
}

export function requestResetPassword(identifier, success, failure) {
    return (dispatch) => {
        return dispatch(serverRequestResetPassword(identifier, success, failure));
    };
}

export function resetPassword(password, success, failure) {
    return (dispatch) => {
        return dispatch(serverResetPassword(password, success, failure));
    };
}

export function restoreStore() {
    return {
        type: constants.RESTORE_STORE,
    };
}

// If the user has obtained their session data by some mechanism other than the
// AUTHENTICATE_USER_REQUEST action, we can trigger the success manually with
// that data (clientId, institutionName, userId, and token).
export function setUserSessionAuthenticationData(data) {
    return {
        type: constants.AUTHENTICATE_USER_SUCCESS,
        data,
    };
}

function serverFetchUserProfile(success, failure) {
    return {
        [CALL_API]: {
            types: [constants.USER_PROFILE_REQUEST, constants.USER_PROFILE_SUCCESS],
            endpoint: '/user/profile',
            success,
            failure,
            forceFailureHandler: true,
        },
    };
}

function serverFetchUserPermissions(success, failure) {
    return {
        [CALL_API]: {
            types: [constants.USER_PERMISSIONS_REQUEST, constants.USER_PERMISSIONS_SUCCESS],
            endpoint: '/users/permissions/global',
            success,
            failure,
            forceFailureHandler: true,
        },
    };
}

function serverFetchUserPreferences(success, failure) {
    return {
        [CALL_API]: {
            types: [constants.FETCH_USER_PREFERENCES_REQUEST, constants.FETCH_USER_PREFERENCES_SUCCESS],
            endpoint: '/user-preferences',
            success,
            failure,
            forceFailureHandler: true,
        },
    };
}

const serverUserLogout = (success, failure) => {
    return {
        [CALL_API]: {
            types: [constants.LOGOUT_USER_REQUEST, constants.LOGOUT_USER_SUCCESS, constants.LOGOUT_USER_FAILURE],
            method: 'POST',
            endpoint: '/user/logout',
            forceFailureHandler: true,
            success,
            failure,
        },
    };
};

function serverAuthenticateUser(data, success, failure) {
    return {
        [CALL_API]: {
            types: [
                constants.AUTHENTICATE_USER_REQUEST,
                constants.AUTHENTICATE_USER_SUCCESS,
                constants.AUTHENTICATE_USER_FAILURE,
            ],
            method: 'POST',
            endpoint: `/auth`,
            forceFailureHandler: true,
            data: data,
            success,
            failure,
        },
    };
}

function serverGetSsoLoginUrl(success, failure) {
    return {
        [CALL_API]: {
            types: [
                constants.GET_CLIENT_SSO_LOGIN_URL_REQUEST,
                constants.GET_CLIENT_SSO_LOGIN_URL_SUCCESS,
                constants.GET_CLIENT_SSO_LOGIN_URL_FAILURE,
            ],
            endpoint: `/auth/sso/url`,
            forceFailureHandler: true,
            success,
            failure,
        },
    };
}

function serverFetchLookups(success, failure) {
    return {
        [CALL_API]: {
            types: [constants.LOOKUPS_REQUEST, constants.LOOKUPS_SUCCESS],
            endpoint: `/lookup-maps`,
            success,
            failure,
        },
    };
}

function serverFetchSettings(success, failure) {
    return {
        [CALL_API]: {
            types: [constants.SETTINGS_REQUEST, constants.SETTINGS_SUCCESS],
            endpoint: `/settings`,
            forceFailureHandler: true,
            success,
            failure,
        },
    };
}

const serverUpdateSettings = (data, success, failure) => {
    return {
        [CALL_API]: {
            types: [constants.SETTINGS_UPDATE_REQUEST, constants.SETTINGS_UPDATE_SUCCESS],
            endpoint: `/settings`,
            method: 'PUT',
            data,
            success,
            failure,
        },
    };
};

function serverSendNotifications(recipients, notification, success, failure) {
    return {
        [CALL_API]: {
            types: [constants.SEND_NOTIFICATIONS_REQUEST, constants.SEND_NOTIFICATIONS_SUCCESS],
            endpoint: '/notifications',
            method: 'POST',
            data: {
                recipients,
                notification,
            },
            success,
            failure,
        },
    };
}

function serverUpdateProfileContact(data, success, failure) {
    return {
        [CALL_API]: {
            types: [constants.PROFILE_CONTACT_EDIT_REQUEST, constants.PROFILE_CONTACT_EDIT_SUCCESS],
            endpoint: `/users/profile/contact`,
            method: 'put',
            data,
            success,
            failure,
        },
    };
}

function serverUpdateProfilePassword(data, success, failure) {
    return {
        [CALL_API]: {
            types: [
                constants.PROFILE_PASSWORD_EDIT_REQUEST,
                constants.PROFILE_PASSWORD_EDIT_SUCCESS,
                constants.PROFILE_PASSWORD_EDIT_FAILURE,
            ],
            endpoint: `/users/profile/password`,
            method: 'put',
            data,
            success,
            failure,
            forceFailureHandler: true,
        },
    };
}

function serverUploadAvatar(file, success, failure, onUploadProgress) {
    return {
        [CALL_API]: {
            types: [constants.PROFILE_UPLOAD_AVATAR_REQUEST, constants.PROFILE_UPLOAD_AVATAR_SUCCESS],
            endpoint: `/users/avatar`,
            data: file,
            method: 'put',
            contentType: 'application/octet-stream',
            success,
            failure,
            onUploadProgress,
        },
    };
}

function serverCheckUserName(userName, success, failure) {
    return {
        [CALL_API]: {
            types: [constants.PROFILE_CHECK_USERNAME_REQUEST, constants.PROFILE_CHECK_USERNAME_SUCCESS],
            method: 'get',
            endpoint: '/users/username-exists',
            params: {
                userName,
            },
            success,
            failure,
        },
    };
}

function serverCheckEmail(userId, email, success, failure) {
    return {
        [CALL_API]: {
            types: [constants.PROFILE_CHECK_EMAIL_REQUEST, constants.PROFILE_CHECK_EMAIL_SUCCESS],
            endpoint: '/users/email-exists',
            params: {
                userId,
                email,
            },
            method: 'get',
            success,
            failure,
        },
    };
}

function serverUpdateUserPreferences(data, success, failure) {
    return {
        [CALL_API]: {
            types: [constants.UPDATE_USER_PREFERENCES_REQUEST, constants.UPDATE_USER_PREFERENCES_SUCCESS],
            endpoint: `/user-preferences`,
            method: 'put',
            data,
            success,
            failure,
        },
    };
}

function serverRequestResetPassword(identifier, success, failure) {
    return {
        [CALL_API]: {
            types: [constants.REQUEST_RESET_PASSWORD_REQUEST, constants.REQUEST_RESET_PASSWORD_SUCCESS],
            endpoint: `/request-reset-password`,
            method: 'POST',
            data: {
                identifier,
            },
            success,
            failure,
            forceFailureHandler: true,
        },
    };
}

function serverResetPassword(password, success, failure) {
    return {
        [CALL_API]: {
            types: [constants.RESET_PASSWORD_REQUEST, constants.RESET_PASSWORD_SUCCESS],
            endpoint: `/reset-password`,
            forceFailureHandler: true,
            method: 'POST',
            data: {
                password,
            },
            success,
            failure,
        },
    };
}
