import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';

export const UPDATE_MESSAGES = Symbol('UPDATE MESSAGES');

// When you import JSON files in Webpack 4+, the actual contents are under the
// returned object's `.default` attribute.

export const importLocaleMessagesFile = (locale, moduleName, smartlingEnabled = false) => {
    const fileName = `${moduleName}${locale === 'en-US' && smartlingEnabled ? '' : `.${locale}`}.json`;
    return import(`../locale${smartlingEnabled ? '' : `/legacy`}/${locale}/${fileName}`).then(
        (module) => module.default,
    );
};

function updateMessages(locale, messagesConfig, next, smartlingEnabled = false) {
    const {module, types} = messagesConfig;

    if (!isString(module)) {
        throw new Error('Specify a string locale. ie:en-US');
    }

    if (!Array.isArray(types)) {
        throw new Error('Expected an array');
    }

    if (!types[0].includes('REQUEST')) {
        // eslint-disable-next-line
        console.warn('Messages action type might not be correct. First is for request:', types[0]);
    }

    if (!types[1].includes('SUCCESS')) {
        // eslint-disable-next-line
        console.warn('Messages action type might not be correct. Second is for success:', types[1]);
    }

    if (types.length > 2 && !types[2].includes('FAILURE')) {
        // eslint-disable-next-line
        console.warn('Messages action type might not be correct. Third is for failure:', types[2]);
    }

    if (!types.every((type) => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.');
    }

    next({
        type: messagesConfig.types[0],
    });

    try {
        importLocaleMessagesFile(locale, module, smartlingEnabled).then((response) => {
            next({
                type: messagesConfig.types[1],
                messages: response,
                module,
            });

            if (!isUndefined(messagesConfig.success)) {
                messagesConfig.success();
            }
        });
    } catch (error) {
        next({
            type: messagesConfig.types[2],
        });

        console.error(error);

        if (!isUndefined(messagesConfig.error)) {
            messagesConfig.error();
        }
    }
}

// Be careful when altering this code. It has a dependency on the locale
// settings in the session store.
//
// This middleware detects when the UPDATE_MESSAGES action is dispatched, gets
// the locale setting from the session store, and uses it to update the
// messages of react-intl.
export default (store) => (next) => (action) => {
    const messagesConfig = action[UPDATE_MESSAGES];

    if (isNil(messagesConfig)) {
        return next(action);
    }

    // UNIT TEST flag is set in webpack config
    if (__UNIT_TEST__) {
        return next({
            type: 'API_CALLS_DISABLED',
        });
    }

    const locale = store.getState().session.settings.get('locale');

    updateMessages(locale, messagesConfig, next);
};
