// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/less-loader/dist/cjs.js!styles/border.less");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
exports.push([module.id, "@keyframes progress-pulsing-circles__pulse___3CI09 {\n  10% {\n    transform: scale(0.5);\n  }\n  50% {\n    transform: scale(1);\n  }\n  90% {\n    transform: scale(0.5);\n  }\n}\n.progress-pulsing-circles__pulse___3CI09 {\n  animation-name: progress-pulsing-circles__pulse___3CI09;\n  animation-iteration-count: infinite;\n}\n.progress-pulsing-circles__circle___FXJ6C {\n  width: 100%;\n  height: 100%;\n  margin-left: 0.1rem;\n  margin-right: 0.1rem;\n  display: inline-block;\n  transform: scale(0.5);\n}\n", ""]);
// Exports
exports.locals = {
	"pulse": "progress-pulsing-circles__pulse___3CI09",
	"circle": "progress-pulsing-circles__circle___FXJ6C " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["darkGrayBG"] + " " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["circle"] + " progress-pulsing-circles__pulse___3CI09"
};
module.exports = exports;
