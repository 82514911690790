import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {routerActions} from 'actions/router-actions';
import {bindActionCreators} from 'redux';

import {setUserSessionAuthenticationData, getSettings} from 'actions/session-actions';
import * as ClientsApi from 'api/clients-api';
import LoginProgress from 'components/login-progress';
import messages from 'intl/anonymous-messages';
import IntlUtils from 'utils/intl-utils';
import {useFlags} from 'launchdarkly-react-client-sdk';

/**
 * The user arrives here after being redirected by our SSO callback endpoint on
 * the backend. The redirect should provide the appropriate auth information on
 * the url query, which this view then uses to set up the user's session on the
 * UI. If the auth information is not on the url, the call to get user profile
 * will fail and cause LOGOUT_USER_REQUEST to be issued. The user will then be
 * redirected to the login view.
 *
 * If the SSO login attempt failed, the user will be redirected back to the
 * login view where an appropriate error message can be displayed and they can
 * try again.
 */
// eslint-disable-next-line no-shadow -- SCLD-17998
const LoginSSOSuccess = ({getSettings, location, replace, setUserSessionAuthenticationData}) => {
    const intl = useIntl();
    const flags = useFlags();
    const {formatMessage} = intl;

    const onLoginSuccess = async () => {
        const searchParams = new URLSearchParams(location.search);
        const authToken = searchParams.get('authToken');
        const userId = searchParams.get('userId');
        const clientId = searchParams.get('clientId');

        const client = await ClientsApi.fetchClient(clientId, userId, authToken);

        // puts the auth info into the session store
        setUserSessionAuthenticationData({
            token: authToken,
            userId,
            clientId,
            institutionName: client.get('institutionName'),
        });

        const goToNextPage = () => {
            replace('/');
        };

        const successFn = (settings) => {
            IntlUtils.updateTimeFormats(settings);
            IntlUtils.updateLocaleReloadAll(goToNextPage, flags.useSmartling);
        };

        const failFn = () => {
            goToNextPage();
        };

        getSettings(successFn, failFn);
    };

    // executes once upon initial load
    useEffect(() => {
        onLoginSuccess();
    }, []);

    return <LoginProgress messageText={formatMessage(messages.authenticating)} />;
};

LoginSSOSuccess.propTypes = {
    getSettings: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    replace: PropTypes.func.isRequired,
    setUserSessionAuthenticationData: PropTypes.func.isRequired,
};

LoginSSOSuccess.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSettings,
            setUserSessionAuthenticationData,
            ...routerActions,
        },
        dispatch,
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSSOSuccess);
