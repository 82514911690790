//#region Legacy Colors from colors.less

const blue = '#3fb9e8';
const darkBlue = '#063c68';
const black = '#2a2d33'; // you are kidding me
const almostBlack = 'rgba(20, 20, 20, 1)';
const darkGray = '#7f8185';
const mediumGray = '#afafaf'; // not dark enough to be a "medium" gray
const gray = '#ccc'; // DO NOT use this for text on a light background
const grayTextLight = 'mediumGray';
const lightGray = '#f0f0f0'; // deprecated, not enough contrast on light background
const lightGray2 = '#dddddd'; // use this instead of the above
const white = '#fff';
const videoBlack = 'almostBlack';

const lilac = '#9e4ca6';
const amaranth = '#f2385a';
const orange = '#fa9200';
const green = '#47b07b';
const lightGreen = '#b4d96a';
const turquoise = '#4ad9d9';
const yellow = '#ffdb3b';

// NEW COLORS
const headingGray = '#626262';
const backgroundLightGray = '#f7f7f7';
const tableCheckboxGray = '#e0e0e0';
const tableBorderGray = '#e5e5e5';
const tableHeadingGray = '#aaaaaa';
const tableSettingGray = '#676767';
const navBackgroundColor = '#205d74';
const verticalLineGray = '#999999';
const lightLiveRed = '#feebee';
const lightYellow = '#ffe880';
const liveRed = '#f1385a';
const warningRed = '#c92c2c';
const offlineGray = '#d8d8d8';

// with opacity
const brandBlueTransparent = 'rgba(63, 185, 232, 0.1)';
const brandBlueTranslucent = 'rgba(63, 185, 232, 0.3)';

//#endregion

const legacyColorMixin = {
    recentDurationGray: {
        color: verticalLineGray,
    },
    liveRed: {
        color: liveRed,
    },
    warningRed: {
        color: warningRed,
    },
    checkboxTableGray: {
        color: tableCheckboxGray,
    },
    tableSettingGray: {
        color: tableSettingGray,
    },
    tableBorderGray: {
        color: tableBorderGray,
    },
    tableHeading: {
        color: tableHeadingGray,
    },
    headingGray: {
        color: headingGray,
    },
    textGrayLight: {
        color: grayTextLight,
    },
    // Text Colors
    turquoise: {
        color: turquoise,
    },
    lightGreen: {
        color: lightGreen,
    },
    green: {
        color: green,
    },
    orange: {
        color: orange,
    },
    amaranth: {
        color: amaranth,
    },
    lilac: {
        color: lilac,
    },
    yellow: {
        color: yellow,
    },
    black: {
        color: black,
    },
    white: {
        color: white,
    },
    brandBlue: {
        color: blue,
    },
    brandDarkBlue: {
        color: darkBlue,
    },
    gray: {
        color: gray,
    },
    lightGray: {
        color: lightGray,
    },
    mediumGray: {
        color: mediumGray,
    },
    darkGray: {
        color: darkGray,
    },
    error: {
        color: amaranth,
    },
    // Backgrounds
    almostBlackBG: {
        backgroundColor: almostBlack,
    },
    videoBlackBG: {
        backgroundColor: videoBlack,
    },
    grayBG: {
        backgroundColor: gray,
    },
    darkGrayBG: {
        backgroundColor: darkGray,
    },
    mediumGrayBG: {
        backgroundColor: mediumGray,
    },
    lightGrayBG: {
        backgroundColor: lightGray2,
    },
    ultraLightGrayBG: {
        backgroundColor: lightGray,
    },
    offlineGrayBG: {
        background: offlineGray,
    },
    noInputsYellowBG: {
        backgroundColor: lightYellow,
    },
    lightLiveRedBG: {
        background: lightLiveRed,
    },
    liveRedBG: {
        background: liveRed,
    },
    yellowBG: {
        background: yellow,
    },
    backgroundLightGray: {
        background: backgroundLightGray,
    },
    navBlueBG: {
        background: navBackgroundColor,
    },
    lightGraySidebarBG: {
        backgroundImage:
            'linear-gradient(0deg, @white 0%, @white 100%), linear-gradient(0deg, @lightGray 0%, @lightGray 100%)',
        backgroundRepeat: 'no-repeat, repeat-y',
        backgroundPosition: '100% 0',
    },
    blackBG: {
        backgroundColor: black,
    },
    brandDarkBlueBG: {
        backgroundColor: darkBlue,
    },
    brandBlueMediumBG: {
        backgroundColor: 'darken(desaturate(@blue, 50%), 10%)',
    },
    blueBG: {
        backgroundColor: blue,
    },
    brandBlueBG: {
        backgroundColor: blue,
    },
    brandBlueTransparentBG: {
        backgroundColor: brandBlueTransparent,
    },
    brandBlueTranslucentBG: {
        backgroundColor: brandBlueTranslucent,
    },
    redBG: {
        backgroundColor: amaranth,
    },
    whiteBG: {
        backgroundColor: white,
    },
    turquoiseBG: {
        backgroundColor: turquoise,
    },
    lightGreenBG: {
        backgroundColor: lightGreen,
    },
    greenBG: {
        backgroundColor: green,
    },
    orangeBG: {
        backgroundColor: orange,
    },
    amaranthBG: {
        backgroundColor: amaranth,
    },
    lilacBG: {
        backgroundColor: lilac,
    },
    // SVG fills
    whiteFill: {
        fill: white,
    },

    blackFill: {
        fill: black,
    },
    grayFill: {
        fill: gray,
    },
    darkGrayFill: {
        fill: gray,
    },
    greenFill: {
        fill: green,
    },
    brandBlueFill: {
        fill: blue,
    },
    // SVG strokes
    whiteStroke: {
        stroke: white,
    },
    brandBlueStroke: {
        stroke: blue,
    },
    grayStroke: {
        stroke: gray,
    },
    darkGrayStroke: {
        stroke: darkGray,
    },
    greenStroke: {
        stroke: green,
    },
    redStroke: {
        stroke: amaranth,
    },
    /**
     * Generated using http://www.cssmatic.com/gradient-generator
     */
    gradientLinearOpacityVBrandBlueBG: {
        background: blue,
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: '-moz-linear-gradient(top, @blue 0%, rgba(0, 0, 0, 0) 100%)',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background:
            '-webkit-gradient(left top, left bottom, color-stop(0%, @blue), color-stop(100%, rgba(0, 0, 0, 0)))',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: '-webkit-linear-gradient(top, @blue 0%, rgba(0, 0, 0, 0) 100%)',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: '-o-linear-gradient(top, @blue 0%, rgba(0, 0, 0, 0) 100%)',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: '-ms-linear-gradient(top, @blue 0%, rgba(0, 0, 0, 0) 100%)',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: 'linear-gradient(to bottom, @blue 0%, rgba(0, 0, 0, 0) 100%)',
    },

    gradientLinearOpacityAmaranthBG: {
        background: amaranth,
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: '-moz-linear-gradient(top, @amaranth 0%, rgba(0, 0, 0, 0) 100%)',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background:
            '-webkit-gradient(left top, left bottom, color-stop(0%, @amaranth), color-stop(100%, rgba(0, 0, 0, 0)))',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: '-webkit-linear-gradient(top, @amaranth 0%, rgba(0, 0, 0, 0) 100%)',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: '-o-linear-gradient(top, @amaranth 0%, rgba(0, 0, 0, 0) 100%)',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: '-ms-linear-gradient(top, @amaranth 0%, rgba(0, 0, 0, 0) 100%)',
        // @ts-expect-error TS(1117) FIXME: An object literal cannot have multiple properties ... Remove this comment to see the full error message
        background: 'linear-gradient(to bottom, @amaranth 0%, rgba(0, 0, 0, 0) 100%)',
    },
};

export default legacyColorMixin;
