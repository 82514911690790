// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/layout.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/border.less");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
var ___CSS_LOADER_ICSS_IMPORT_3___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/typography.less");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("images/sc-logo-short.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_3___, "", true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".passthrough__main___1uPHc {\n  width: 39rem;\n}\n.passthrough__boxWithLogo___3YRHC {\n  top: 25rem;\n  padding: 1.9rem 3.4rem 2.9rem 3.4rem;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.passthrough__authenticatingText___sRGl6 {\n  padding-bottom: 1rem;\n}\n.passthrough__simcaptureLogo___3NrP7 {\n  width: 100%;\n  height: 4.5rem;\n  margin-top: 2rem;\n  margin-bottom: 1.5rem;\n  background-size: contain;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") scroll no-repeat 50% 0;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "passthrough__main___1uPHc " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["center"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["fillHeightMinusFooter"] + "",
	"boxWithLogo": "passthrough__boxWithLogo___3YRHC " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["basic"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["whiteBG"] + "",
	"authenticatingText": "passthrough__authenticatingText___sRGl6 " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["darkGray"] + " " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["large"] + "",
	"simcaptureLogo": "passthrough__simcaptureLogo___3NrP7"
};
module.exports = exports;
