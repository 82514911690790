import * as ApiUtils from 'utils/api-utils';

/**
 * @typedef {import('immutable').Map} ImmutableMap
 * @typedef {import('immutable').List} ImmutableList
 */

/**
 * @typedef {Object} HonorCode
 * @property {string} honorCodeId
 * @property {string} text
 * @property {string} name
 * @property {boolean} default
 */

export const fetchSettings = () => {
    return ApiUtils.callApiMeteorAuthenticatedUnsafe({
        endpoint: `/settings`,
        method: 'GET',
    });
};

export const updateSettings = (payload) => {
    return ApiUtils.callApiMeteorAuthenticatedUnsafe({
        endpoint: `/settings`,
        method: 'PUT',
        data: payload,
    });
};

export const fetchNotificationOptOuts = (client, user) => {
    return ApiUtils.callApiMeteor(client, user, {
        endpoint: `/settings/notifications`,
        method: 'GET',
    });
};

export const fetchGlobalNotificationOptOuts = (client, user) => {
    return ApiUtils.callApiMeteor(client, user, {
        endpoint: `/settings/notifications/global`,
        method: 'GET',
    });
};

export const fetchHonorCodes = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/settings/honor-codes',
        method: 'GET',
    });
};

/**
 * Get the default honor code for a client
 * @returns {Promise<*>}
 */
export const fetchDefaultHonorCode = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/settings/honor-code`,
        method: 'GET',
    });
};

/**
 * @param {HonorCode} honorCode
 * @returns {Promise<*>}
 */
export const updateHonorCode = (honorCode) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/settings/honor-code`,
        method: 'POST',
        data: honorCode,
    });
};

/**
 * @param honorCodeId
 * @returns {Promise<*>}
 */
export const deleteHonorCode = (honorCodeId) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/settings/honor-code/${honorCodeId}`,
        method: 'DELETE',
    });
};

export const fetchServicesUnsafe = () => {
    return ApiUtils.callApiMeteorAuthenticatedUnsafe({
        endpoint: '/settings/services',
        method: 'GET',
    });
};

/**
 * Enable or disable editable app features
 * @param {object} appFeatureSettings
 * @returns {Promise<null>}
 */
export const updateAppFeatures = (appFeatureSettings) => {
    return ApiUtils.callApiMeteorAuthenticated({
        method: 'post',
        endpoint: '/settings/app-features',
        data: appFeatureSettings,
    });
};

/**
 * Get all IP video feeds for a client.
 *
 * @return {Promise<ImmutableList>}
 */
export const fetchIpVideoFeeds = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/settings/ip-feeds/video/',
        method: 'GET',
    });
};

/**
 * @param {string} ipVideoFeedId
 * @return {Promise<ImmutableMap>}
 */
export const fetchIpVideoFeed = (ipVideoFeedId) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/settings/ip-feeds/video/${ipVideoFeedId}`,
        method: 'GET',
    });
};

/**
 * @param {ImmutableMap} payload
 * @return {Promise<ImmutableMap>}
 */
export const updateIpVideoFeed = (payload) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/settings/ip-feeds/video/',
        method: 'PUT',
        data: payload,
    });
};

/**
 * @param {ImmutableMap} payload
 * @return {Promise<ImmutableMap>}
 */
export const createIpVideoFeed = (payload) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/settings/ip-feeds/video/',
        method: 'POST',
        data: payload,
    });
};

/**
 * Delete an IP video feed by id.
 *
 * @param {string} ipVideoFeedId
 * @return {Promise<ImmutableMap>} Hapi response.
 */
export const deleteIpVideoFeed = (ipVideoFeedId) => {
    return ApiUtils.callApiMeteorAuthenticated({
        method: 'DELETE',
        endpoint: `/settings/ip-feeds/video/${ipVideoFeedId}`,
    });
};

/**
 * Get all IP audio feeds for a client.
 *
 * @return {Promise<ImmutableList>}
 */
export const fetchIpAudioFeeds = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/settings/ip-feeds/audio/',
        method: 'GET',
    });
};

/**
 * @param {string} ipAudioFeedId
 * @return {Promise<ImmutableMap>}
 */
export const fetchIpAudioFeed = (ipAudioFeedId) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/settings/ip-feeds/audio/${ipAudioFeedId}`,
        method: 'GET',
    });
};

/**
 * @param {ImmutableMap} payload
 * @return {Promise<ImmutableMap>}
 */
export const updateIpAudioFeed = (payload) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/settings/ip-feeds/audio/',
        method: 'PUT',
        data: payload,
    });
};

/**
 * @param {ImmutableMap} payload
 * @return {Promise<ImmutableMap>}
 */
export const createIpAudioFeed = (payload) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/settings/ip-feeds/audio/',
        method: 'POST',
        data: payload,
    });
};

/**
 * Delete an IP audio feed by id.
 *
 * @param {string} ipAudioFeedId
 * @return {Promise<ImmutableMap>} Hapi response.
 */
export const deleteIpAudioFeed = (ipAudioFeedId) => {
    return ApiUtils.callApiMeteorAuthenticated({
        method: 'DELETE',
        endpoint: `/settings/ip-feeds/audio/${ipAudioFeedId}`,
    });
};

/**
 * Fetches the environment settings. These settings are not specific to a client.
 */
export const fetchEnvironment = () => {
    return ApiUtils.callApiMeteorUnauthenticatedUnsafe({
        endpoint: `/settings/environment`,
        method: 'GET',
    });
};

export const fetchSimcaptureApiVersion = () => {
    return ApiUtils.callApiMeteorUnauthenticatedUnsafe({
        endpoint: `/settings/versions/simcapture-api`,
        method: 'GET',
    });
};

export const updateLayout = (payload) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/settings/layouts/update`,
        method: 'PUT',
        data: payload,
    });
};

export const exportLayout = (payload) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/settings/layouts/export`,
        method: 'PUT',
        data: payload,
    });
};
