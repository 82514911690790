import {createContext} from 'react';
import {AccessibilityOptions} from 'views/signed-in/accessibility/accessibility-constants';
import noop from 'lodash/noop';

type ContextType = {
    accessibilitySettings: AccessibilityOptions;
    setAccessibilitySettings: (values: AccessibilityOptions) => void;
};

export const AccessibilityContext = createContext<ContextType>({
    accessibilitySettings: {
        theme: 'LIGHT',
        motion: 'NORMAL',
    },
    setAccessibilitySettings: noop,
});
