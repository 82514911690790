/**
 * Datadog applications exist in 2 organizations, development and production.
 * Each organization has a SimCapture Cloud RUM application.
 * Both a client token and an application ID are required to initialize the RUM
 * application.
 * Documentation: https://blinemedical.atlassian.net/wiki/spaces/SD/pages/3164078092
 */
// Credentials for the SimCapture Cloud application belonging to the Datadog Development organization.
export const DATADOG_DEVELOPMENT_SIMCAPTURE_CLOUD_APPLICATION_ID = '88abcdfc-cb41-443d-b7ba-046446002cec';
export const DATADOG_DEVELOMENT_SIMCAPTURE_CLOUD_CLIENT_TOKEN = 'pub7a95a08bb32bb4421adea58603ccfa31';

// Credentials for the SimCapture Cloud application belonging to the Datadog Production organization.
export const DATADOG_PRODUCTION_SIMCAPTURE_CLOUD_APPLICATION_ID = '2ad8f4be-8264-49fd-bb48-4aaa85902f4c';
export const DATADOG_PRODUCTION_SIMCAPTURE_CLOUD_CLIENT_TOKEN = 'pub6c8e9c877f12a4fc816fb7ec41c69d17';

export const DATADOG_DEVELOPMENT_BASE_URL = 'https://laerdal-labs-dev.datadoghq.com';
export const DATADOG_PRODUCTION_BASE_URL = 'https://laerdal-labs.datadoghq.com';

export const LAUNCH_DARKLY_CLIENT_IDS = {
    local: '66c8d59847fba20fc42375bb',
    development: '66c8d59847fba20fc42375bd',
    feature: '66c8d59847fba20fc42375bd',
    staging: '66c8d59847fba20fc42375bf',
    production: '66c8d59847fba20fc42375b9',
};

export const SERVICE_CONSUMERS = {
    SIMCAPTURE_CLOUD: 'simcapture-cloud',
};
