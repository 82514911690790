export type CAPTURE_HOST_STATE_UPDATE = 'capture-host-state-update';
export type CENTER_SIGN_IN_UPDATE = 'center-sign-in-update';
export type EXAMS_ENTER_REMOTE_EXAM_WAITING_ROOM = 'exams-enter-remote-exam-waiting-room';
export type EXAMS_USER_ENTERED_REMOTE_EXAM_WAITING_ROOM = 'exams-user-entered-remote-exam-waiting-room';
export type EXAMS_USER_LEFT_REMOTE_EXAM_WAITING_ROOM = 'exams-user-left-remote-exam-waiting-room';
export type EXAM_HONOR_CODE_ACCEPTED = 'exam-honor-code-accepted';
export type EXAM_ROOM_START = 'exam-room-start';
export type FORCE_ENTER_REMOTE_ROOM = 'force-enter-remote-room';
export type JOINED_WAITING_ROOM = 'joined-waiting-room';
export type REMOTE_ENCOUNTER_WEBRTC_ANNOUNCE = 'remote-encounter-webrtc-announce';
export type REMOTE_ENCOUNTER_WEBRTC_ANSWER = 'remote-encounter-webrtc-answer';
export type REMOTE_ENCOUNTER_WEBRTC_ICE_CANDIDATE = 'remote-encounter-webrtc-ice-candidate';
export type REMOTE_ENCOUNTER_WEBRTC_OFFER = 'remote-encounter-webrtc-offer';
export type REMOTE_ROOM_OPENED = 'remote-room-opened';
export type SESSION_ANNOTATE = 'annotate-session';
export type SESSION_DELETE = 'delete-session';
export type SESSION_DELETE_SIMDATA = 'delete-session-simulator-data';
export type SESSION_DELETE_VIDEOS = 'delete-session-videos';
export type SESSION_START = 'start-session';
export type SESSION_STOP = 'stop-session';
export type SESSION_UPDATE = 'update-session';
export type SESSION_IMPORT_FAILED = 'import-session-failed';
export type WEBRTC_SESSION_CREATE = 'webrtc-session-create';
export type WEBRTC_SESSION_DELETE = 'webrtc-session-delete';
export type WEBRTC_SESSION_END = 'webrtc-session-end';
export type WEBRTC_SESSION_ICE = 'webrtc-session-ice';
export type WEBRTC_SESSION_SDP = 'webrtc-session-sdp';
export type WEBRTC_PAGING_SESSION_CREATE = 'webrtc-paging-session-create';
export type ROOM_UPDATED = 'room-updated';

export type PDL_SELF_RECORD_INIT = 'pdl-self-record-init';
export type NIGHTINGALE_SELF_RECORD_INIT = 'nighintgale-self-record-init';
export type NIGHTINGALE_SELF_RECORD_START = 'nightingale-self-record-start';
export type NIGHTINGALE_SELF_RECORD_STARTED = 'nightingale-self-record-started';
export type NIGHTINGALE_SELF_RECORD_STOP = 'nightingale-self-record-stop';
export type NIGHTINGALE_SELF_RECORD_STOPPED = 'nightingale-self-record-stopped';
export type NIGHTINGALE_SELF_RECORD_VIDEO_PROCESSED = 'nightingale-self-record-video-processed';
export type NIGHTINGALE_SELF_RECORD_FAILED = 'nightingale-self-record-failed';

export type NIGHTINGALE_START_MULTIDEVICE = 'nightingale-start-multidevice';
export type NIGHTINGALE_JOIN_MULTIDEVICE = 'nightingale-join-multidevice';
export type NIGHTINGALE_SUBMITTED_MULTIDEVICE = 'nightingale-submitted-multidevice';
export type NIGHTINGALE_DISCONNECT_MULTIDEVICE = 'nightingale-disconnect-multidevice';
export type NIGHTINGALE_CANCEL_MULTIDEVICE = 'nightingale-cancel-multidevice';
export type NIGHTINGALE_EXIT_MULTIDEVICE = 'nightingale-exit-multidevice';
export type NIGHTINGALE_JOIN_ROOM = 'nightingale-join-room';

export type SUBSCRIBE_LIVE_LOCATION = 'subscribe-live-location';
export type SUBSCRIBE_DASHBOARD_LIVE_LOCATION = 'subscribe-dashboard-live-location';
export type UNSUBSCRIBE_LIVE_LOCATION = 'unsubscribe-live-location';
export type UNSUBSCRIBE_DASHBOARD_LIVE_LOCATION = 'unsubscribe-dashboard-live-location';

export type MOBILE_RECORD_DEVICE_PAIRING_SUCCESS = 'mobile-record-device-pairing-success';
export type MOBILE_RECORD_DEVICE_PAIRING_FAILED = 'mobile-record-device-pairing-failed';
export type MOBILE_RECORD_DEVICE_LOCATION_ASSIGNED = 'mobile-record-device-location-assigned';
export type MOBILE_RECORD_DEVICE_LOCATION_UNASSIGNED = 'mobile-record-device-location-unassigned';
export type MOBILE_RECORD_DEVICE_UNPAIRED = 'mobile-record-device-unpaired';
export type MOBILE_RECORD_DEVICE_SESSION_STARTED = 'mobile-record-device-session-started';
export type MOBILE_RECORD_DEVICE_LEAVE_SESSION = 'mobile-record-device-leave-session';
export type MOBILE_RECORD_DEVICE_PTZ = 'mobile-record-device-ptz';
export type MOBILE_RECORD_DEVICE_UPDATED = 'mobile-record-device-updated';

export type MESSAGING_EVENT_MONITOR_CONNECT = 'messaging-event-monitor-connect';
export type MESSAGING_EVENT_MONITOR_ENTER_ROOM = 'messaging-event-monitor-enter-room';
export type MESSAGING_EVENT_MONITOR_LEAVE_ROOM = 'messaging-event-monitor-leave-room';
export type MESSAGING_EVENT_MONITOR_SEND_MESSAGE = 'messaging-event-monitor-send-message';
export type MESSAGING_EVENT_RECEIVE_MESSAGES = 'messaging-event-receive-messages';
export type MESSAGING_EVENT_SP_ENTER_ROOM = 'messaging-event-sp-enter-room';
export type MESSAGING_EVENT_SP_SEND_MESSAGE = 'messaging-event-sp-send-message';
export type MESSAGING_EVENT_READ_MESSAGES = 'messaging-event-read-messages';

const SocketUIEvents = {
    CAPTURE_HOST_STATE_UPDATE: 'capture-host-state-update' as CAPTURE_HOST_STATE_UPDATE,
    CENTER_SIGN_IN_UPDATE: 'center-sign-in-update' as CENTER_SIGN_IN_UPDATE,
    EXAMS_ENTER_REMOTE_EXAM_WAITING_ROOM:
        'exams-enter-remote-exam-waiting-room' as EXAMS_ENTER_REMOTE_EXAM_WAITING_ROOM,
    EXAMS_USER_ENTERED_REMOTE_EXAM_WAITING_ROOM:
        'exams-user-entered-remote-exam-waiting-room' as EXAMS_USER_ENTERED_REMOTE_EXAM_WAITING_ROOM,
    EXAMS_USER_LEFT_REMOTE_EXAM_WAITING_ROOM:
        'exams-user-left-remote-exam-waiting-room' as EXAMS_USER_LEFT_REMOTE_EXAM_WAITING_ROOM,
    EXAM_HONOR_CODE_ACCEPTED: 'exam-honor-code-accepted' as EXAM_HONOR_CODE_ACCEPTED,
    FORCE_ENTER_REMOTE_ROOM: 'force-enter-remote-room' as FORCE_ENTER_REMOTE_ROOM,
    JOINED_WAITING_ROOM: 'joined-waiting-room' as JOINED_WAITING_ROOM,
    REMOTE_ENCOUNTER_WEBRTC_ANNOUNCE: 'remote-encounter-webrtc-announce' as REMOTE_ENCOUNTER_WEBRTC_ANNOUNCE,
    REMOTE_ENCOUNTER_WEBRTC_ANSWER: 'remote-encounter-webrtc-answer' as REMOTE_ENCOUNTER_WEBRTC_ANSWER,
    REMOTE_ENCOUNTER_WEBRTC_ICE_CANDIDATE:
        'remote-encounter-webrtc-ice-candidate' as REMOTE_ENCOUNTER_WEBRTC_ICE_CANDIDATE,
    REMOTE_ENCOUNTER_WEBRTC_OFFER: 'remote-encounter-webrtc-offer' as REMOTE_ENCOUNTER_WEBRTC_OFFER,
    REMOTE_ROOM_OPENED: 'remote-room-opened' as REMOTE_ROOM_OPENED,
    EXAM_ROOM_START: 'exam-room-start' as EXAM_ROOM_START,
    SESSION_ANNOTATE: 'annotate-session' as SESSION_ANNOTATE,
    SESSION_DELETE: 'delete-session' as SESSION_DELETE,
    SESSION_DELETE_SIMDATA: 'delete-session-simulator-data' as SESSION_DELETE_SIMDATA,
    SESSION_DELETE_VIDEOS: 'delete-session-videos' as SESSION_DELETE_VIDEOS,
    SESSION_START: 'start-session' as SESSION_START,
    SESSION_STOP: 'stop-session' as SESSION_STOP,
    SESSION_UPDATE: 'update-session' as SESSION_UPDATE,
    SESSION_IMPORT_FAILED: 'import-session-failed' as SESSION_IMPORT_FAILED,
    WEBRTC_SESSION_CREATE: 'webrtc-session-create' as WEBRTC_SESSION_CREATE,
    WEBRTC_SESSION_DELETE: 'webrtc-session-delete' as WEBRTC_SESSION_DELETE,
    WEBRTC_SESSION_END: 'webrtc-session-end' as WEBRTC_SESSION_END,
    WEBRTC_SESSION_ICE: 'webrtc-session-ice' as WEBRTC_SESSION_ICE,
    WEBRTC_SESSION_SDP: 'webrtc-session-sdp' as WEBRTC_SESSION_SDP,
    WEBRTC_PAGING_SESSION_CREATE: 'webrtc-paging-session-create' as WEBRTC_PAGING_SESSION_CREATE,
    ROOM_UPDATED: 'room-updated' as ROOM_UPDATED,

    PDL_SELF_RECORD_INIT: 'pdl-self-record-init' as PDL_SELF_RECORD_INIT,
    NIGHTINGALE_SELF_RECORD_INIT: 'nighintgale-self-record-init' as NIGHTINGALE_SELF_RECORD_INIT,
    NIGHTINGALE_SELF_RECORD_START: 'nightingale-self-record-start' as NIGHTINGALE_SELF_RECORD_START,
    NIGHTINGALE_SELF_RECORD_STARTED: 'nightingale-self-record-started' as NIGHTINGALE_SELF_RECORD_STARTED,
    NIGHTINGALE_SELF_RECORD_STOP: 'nightingale-self-record-stop' as NIGHTINGALE_SELF_RECORD_STOP,
    NIGHTINGALE_SELF_RECORD_STOPPED: 'nightingale-self-record-stopped' as NIGHTINGALE_SELF_RECORD_STOPPED,
    NIGHTINGALE_SELF_RECORD_VIDEO_PROCESSED:
        'nightingale-self-record-video-processed' as NIGHTINGALE_SELF_RECORD_VIDEO_PROCESSED,
    NIGHTINGALE_SELF_RECORD_FAILED: 'nightingale-self-record-failed' as NIGHTINGALE_SELF_RECORD_FAILED,

    NIGHTINGALE_START_MULTIDEVICE: 'nightingale-start-multidevice' as NIGHTINGALE_START_MULTIDEVICE,
    NIGHTINGALE_JOIN_MULTIDEVICE: 'nightingale-join-multidevice' as NIGHTINGALE_JOIN_MULTIDEVICE,
    NIGHTINGALE_SUBMITTED_MULTIDEVICE: 'nightingale-submitted-multidevice' as NIGHTINGALE_SUBMITTED_MULTIDEVICE,
    NIGHTINGALE_DISCONNECT_MULTIDEVICE: 'nightingale-disconnect-multidevice' as NIGHTINGALE_DISCONNECT_MULTIDEVICE,
    NIGHTINGALE_CANCEL_MULTIDEVICE: 'nightingale-cancel-multidevice' as NIGHTINGALE_CANCEL_MULTIDEVICE,
    NIGHTINGALE_EXIT_MULTIDEVICE: 'nightingale-exit-multidevice' as NIGHTINGALE_EXIT_MULTIDEVICE,
    NIGHTINGALE_JOIN_ROOM: 'nightingale-join-room' as NIGHTINGALE_JOIN_ROOM,

    SUBSCRIBE_LIVE_LOCATION: 'subscribe-live-location' as SUBSCRIBE_LIVE_LOCATION,
    SUBSCRIBE_DASHBOARD_LIVE_LOCATION: 'subscribe-dashboard-live-location' as SUBSCRIBE_DASHBOARD_LIVE_LOCATION,
    UNSUBSCRIBE_LIVE_LOCATION: 'unsubscribe-live-location' as UNSUBSCRIBE_LIVE_LOCATION,
    UNSUBSCRIBE_DASHBOARD_LIVE_LOCATION: 'unsubscribe-dashboard-live-location' as UNSUBSCRIBE_DASHBOARD_LIVE_LOCATION,

    MOBILE_RECORD_DEVICE_PAIRING_SUCCESS:
        'mobile-record-device-pairing-success' as MOBILE_RECORD_DEVICE_PAIRING_SUCCESS,
    MOBILE_RECORD_DEVICE_PAIRING_FAILED: 'mobile-record-device-pairing-failed' as MOBILE_RECORD_DEVICE_PAIRING_FAILED,
    MOBILE_RECORD_DEVICE_LOCATION_ASSIGNED:
        'mobile-record-device-location-assigned' as MOBILE_RECORD_DEVICE_LOCATION_ASSIGNED,
    MOBILE_RECORD_DEVICE_LOCATION_UNASSIGNED:
        'mobile-record-device-location-unassigned' as MOBILE_RECORD_DEVICE_LOCATION_UNASSIGNED,
    MOBILE_RECORD_DEVICE_UNPAIRED: 'mobile-record-device-unpaired' as MOBILE_RECORD_DEVICE_UNPAIRED,
    MOBILE_RECORD_DEVICE_SESSION_STARTED:
        'mobile-record-device-session-started' as MOBILE_RECORD_DEVICE_SESSION_STARTED,
    MOBILE_RECORD_DEVICE_LEAVE_SESSION: 'mobile-record-device-leave-session' as MOBILE_RECORD_DEVICE_LEAVE_SESSION,
    MOBILE_RECORD_DEVICE_PTZ: 'mobile-record-device-ptz' as MOBILE_RECORD_DEVICE_PTZ,
    MOBILE_RECORD_DEVICE_UPDATED: 'mobile-record-device-updated' as MOBILE_RECORD_DEVICE_UPDATED,

    MESSAGING_EVENT_MONITOR_CONNECT: 'messaging-event-monitor-connect' as MESSAGING_EVENT_MONITOR_CONNECT,
    MESSAGING_EVENT_MONITOR_ENTER_ROOM: 'messaging-event-monitor-enter-room' as MESSAGING_EVENT_MONITOR_ENTER_ROOM,
    MESSAGING_EVENT_MONITOR_LEAVE_ROOM: 'messaging-event-monitor-leave-room' as MESSAGING_EVENT_MONITOR_LEAVE_ROOM,
    MESSAGING_EVENT_MONITOR_SEND_MESSAGE:
        'messaging-event-monitor-send-message' as MESSAGING_EVENT_MONITOR_SEND_MESSAGE,
    MESSAGING_EVENT_RECEIVE_MESSAGES: 'messaging-event-receive-messages' as MESSAGING_EVENT_RECEIVE_MESSAGES,
    MESSAGING_EVENT_SP_ENTER_ROOM: 'messaging-event-sp-enter-room' as MESSAGING_EVENT_SP_ENTER_ROOM,
    MESSAGING_EVENT_SP_SEND_MESSAGE: 'messaging-event-sp-send-message' as MESSAGING_EVENT_SP_SEND_MESSAGE,
    MESSAGING_EVENT_READ_MESSAGES: 'messaging-event-read-messages' as MESSAGING_EVENT_READ_MESSAGES,
};

export default SocketUIEvents;
