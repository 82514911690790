/**
 * Beware! Password strengths are defined as numbers in the database, starting from 1 NOT 0. Subtract 1 from the number
 * to obtain an index into this array.
 *
 * Weak (1): one or more characters of any type.
 *
 * Medium (2): 8 or more characters including at least one upper case, one lower
 * case, and one number.
 *
 * Strong (3): 10 or more characters including at least one upper case, one lower
 * case, one number, and one of the following special characters:
 * - `~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/
 */
export const PASSWORD_REQUIREMENTS_BY_STRENGTH = [
    /^.{1,}$/,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~\\!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]).{10,}/
] as const;
