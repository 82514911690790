//#region Legacy Typography from typography.less

const fontSizeTiny = '1rem';
const fontSizeSmall = '1.2rem';
const fontSizeNormal = '1.4rem';
const fontSizeMedium = '1.6rem';
const fontSizeLarge = '2rem';
const fontSizeHuge = '2.8rem';
const fontSizeEnormous = '4.8rem';
const lineHeightSmall = '1.5rem';
const lineHeightNormal = '2rem';
const lineHeightMedium = '2.4rem';
const lineHeightLarge = '3rem';
const lineHeightHuge = '4rem';

const iconBaseSize = '2.4rem';

//#endregion

const legacyTypographyMixin = {
    materialIcon: {
        fontFamily: 'Material Icons',
        fontStyle: 'normal',
        fontWeight: 400,
        textTransform: 'none',
        letterSpacing: 'normal',
        wordWrap: 'normal',
        whiteSpace: 'nowrap',
        direction: 'ltr',

        // Support for all WebKit browsers.
        '-webkit-font-smoothing': 'antialiased',
        // Support for Safari and Chrome.
        'text-rendering': 'optimizeLegibility',

        // Support for Firefox.
        '-moz-osx-font-smoothing': 'grayscale',

        // Support for IE.
        'font-feature-settings': 'liga',
    },
    iconBase: {
        fontSize: iconBaseSize,
    },
    iconCentered: {
        composes: 'materialIcon',
        textAlign: 'center', // if icon element width exceeds icon character width
        verticalAlign: 'middle', // if icon element height exceeds icon character height
        userSelect: 'none',
    },
    mainFont: {
        fontFamily: 'Lato, sans-serif',
    },
    center: {
        textAlign: 'center',
    },
    tiny: {
        fontSize: fontSizeTiny,
        fontWeight: 400,
    },
    small: {
        fontSize: fontSizeSmall,
        lineHeight: lineHeightSmall,
    },
    normal: {
        fontSize: fontSizeNormal,
        lineHeight: lineHeightNormal,
        fontWeight: 'normal',
    },
    medium: {
        fontSize: fontSizeMedium,
        fontWeight: 300,
    },
    mediumLineHeight: {
        lineHeight: lineHeightMedium,
    },
    large: {
        fontSize: fontSizeLarge,
        fontWeight: 300,
    },
    largeLineHeight: {
        lineHeight: lineHeightLarge,
    },
    huge: {
        fontSize: fontSizeHuge,
        fontWeight: 300,
    },
    hugeLineHeight: {
        lineHeight: lineHeightHuge,
    },
    enormous: {
        fontSize: fontSizeEnormous,
        fontWeight: 300,
    },
    enormousLineHeight: {
        lineHeight: fontSizeEnormous,
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    underline: {
        textDecoration: 'underline',
    },
    noSelect: {
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none', // IE 10+
        'user-select': 'none',
    },
};

export default legacyTypographyMixin;
