import * as constants from 'constants/intl-constants';

import indexOf from 'lodash/indexOf';
import union from 'lodash/union';

import {createReducer} from 'redux-store';

const initialState = () => {
    return {
        formats: {
            /**
             * Warning: do not specify any `timeZone` setting in these formats. Most
             * browsers do not support any value for `timeZone` other than 'UTC', so
             * we need to fall back to the local time to avoid throwing exceptions.
             */
            date: {
                longDateTime: {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                },
                preciseDateTime: {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                },
                shortDateTime: {
                    // Sep 20, 2019, 3:12 PM
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                },
                mediumDate: {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                },
                longDate: {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                },
                shortDate: {
                    // en-US: 09/20/2019
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                },
                date: {
                    day: 'numeric',
                },
                month: {
                    month: 'long',
                },
                monthAndDay: {
                    month: 'short',
                    day: 'numeric',
                },
                yearAndMonth: {
                    year: 'numeric',
                    month: 'short',
                },
                timeOfDay: {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                },
            },
            // For duration, we can use the local browser timezone.
            duration: {
                sessionTicks: {
                    minute: 'numeric',
                    second: 'numeric',
                },
            },
        },
        messages: {},
        modules: [],
    };
};

const actionsMap = {
    [constants.MESSAGES_SUCCESS]: (state, action) => {
        if (indexOf(state.modules, action.module) === -1) {
            state.modules.push(action.module);
        }
        return {
            messages: {...state.messages, ...action.messages},
            modules: state.modules,
        };
    },
    [constants.SET_MESSAGES]: (state, action) => {
        return {
            messages: {...state.messages, ...action.messages},
            modules: union(state.modules, action.moduleNames),
        };
    },
    [constants.UPDATE_FORMATS]: (state, action) => {
        return {
            formats: {...state.formats, ...action.formats},
        };
    },
};

export default createReducer(initialState(), actionsMap);
