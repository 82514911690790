import {parseJsonOrReturnString} from 'utils/string-utils';

export default class LocalStorageUtils {
    static set = (key, value) => {
        localStorage[key] = JSON.stringify(value);
    };

    static get = (item) => {
        const obj = localStorage[item];
        return parseJsonOrReturnString(obj);
    };

    static remove = (key) => {
        localStorage.removeItem(key);
    };
}
