export const da_small = `~'only screen and (min-width: 768px)'`;
export const da_medium = `~'only screen and (min-width: 1024px)'`;
export const da_large = `~'only screen and (min-width: 1280px)'`;
export const da_x_large = `~'only screen and (min-width: 1366px)'`;

export const da_detail = `~'only screen and (min-width: 880px)'`;

// TV monitors
export const ds_tv_monitor = `~'only screen and (min-width: 1920px) and (min-resolution: 1dppx)'`;

// MS Surface
export const ds_ms_surface = `~'only screen and (device-aspect-ratio: 2/3) and (min-resolution: 2dppx), only screen and (device-aspect-ratio: 3/2) and (min-resolution: 2dppx)'`;

export const page_width_small = '76.8rem';
export const page_width_medium = '96rem';
export const page_width_large = '115.2rem';
export const page_width_x_large = '129.6rem';
