import * as ApiUtils from 'utils/api-utils';

export const fetchSettings = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/settings',
        method: 'get',
    });
};

export const fetchUserProfile = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/user/profile',
        method: 'get',
    });
};

export const fetchUserPermissions = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/permissions/global',
        method: 'get',
    });
};

export const fetchUserPreferences = () => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/user-preferences',
        method: 'get',
    });
};
