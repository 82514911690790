import PropTypes from 'prop-types';
import formControl from '../forms/form-control';
import FormLabel from './form-label';
import FormErrors from './form-errors';
import inputPropTypes from '../forms/utils/input-prop-types';
import formControlPropTypes from '../forms/utils/form-control-prop-types';
import {inputValidError} from '../forms/utils/helpers';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import omit from 'lodash/omit';

import CSSUtils from 'utils/css-utils';

const {applyClassName} = CSSUtils;
import S from './form-input.less';

const {oneOf, string, bool, func, object} = PropTypes;

/**
 * @deprecated Use Formik and material-ui components instead.
 *
 * Text input field with the standard label and validation error message.
 * Composes the standard form styling.
 */
@formControl(inputValidError)
export default class FormInput extends React.Component {
    static propTypes = {
        label: string,
        helpText: string,
        disabled: bool,
        showHelp: bool,
        showErrorMessage: bool,
        shouldHighlightOnFocusFirstTime: bool,
        onEnter: func,
        style: object,
        type: oneOf(['text', 'number', 'password']),
        ...formControlPropTypes(PropTypes),
        ...inputPropTypes(PropTypes),
    };

    static defaultProps = {
        style: {},
        label: '',
        value: '',
        helpText: '',
        showHelp: true,
        showErrorMessage: true,
        shouldHighlightOnFocusFirstTime: false,
        disabled: false,
        onEnter: noop,
        type: 'text',
        autoFocus: false,
    };

    componentDidMount() {
        if (this.props.autoFocus) {
            this._input.focus();
        }
    }

    get valid() {
        return this.props.valid;
    }

    get dirty() {
        return !this.props.pristine;
    }

    get touched() {
        return this.props.touched;
    }

    /**
     * Display this component as invalid if it is invalid AND either:
     * - It is both dirty and touched, i.e. the user has entered data.
     * OR
     * - The user has tried to submit the form, and has to be reminded that
     * there are still invalid inputs that require their attention.
     */
    displayAsInvalid() {
        return !this.valid && (this.props.triedSubmit || (this.dirty && this.touched));
    }

    _onChange(event) {
        const v = event.target.value;
        this.props.onChange(v);
    }

    _onKeyDown(e) {
        if (e.key === 'Enter') {
            this.props.onEnter(e);
        }
    }

    _highlightOnFocusFirstTime(event) {
        if (this.props.shouldHighlightOnFocusFirstTime && isNil(this.highlightedOnFocus)) {
            event.target.select();
            this.highlightedOnFocus = true;
        }
    }

    render() {
        let {
            id,
            label,
            helpText,
            showHelp,
            showErrorMessage,
            disabled,
            onTouched,
            value,
            type,
            style,
            messages,
            autoFocus,
        } = this.props;
        const {errors} = messages;

        let inputLeftClassName = 'inputLeft';
        if (!showHelp) {
            inputLeftClassName = 'inputLeftFull';
        }

        let inputClass = applyClassName('inputDisabled', S, style);
        if (!disabled) {
            if (this.displayAsInvalid()) {
                inputClass = applyClassName('inputInvalid', S, style);
            } else {
                inputClass = applyClassName('inputValid', S, style);
            }
        }

        if (!value) {
            value = '';
        }

        const inputProps = omit(this.props, [
            'disabled',
            'id',
            'isValidating',
            'showErrorMessage',
            'helpText',
            'showHelp',
            'label',
            'messages',
            'onChange',
            'onEnter',
            'onTouched',
            'pristine',
            'touched',
            'valid',
            'triedSubmit',
            'validator',
            'type',
            'value',
            'shouldHighlightOnFocusFirstTime',
            'autoFocus',
        ]);

        const helpTextClass = disabled ? S.disabled : S.enabled;
        let help;
        if (this.displayAsInvalid() && showHelp) {
            help = <div className={S.helpTextInvalid}>{helpText}</div>;
        } else if (showHelp) {
            help = (
                <div className={S.helpText}>
                    <span className={helpTextClass}>{helpText}</span>
                </div>
            );
        }
        const labelClassName = 'label';

        return (
            <div className={S.container}>
                <div className={S.row}>
                    <div className={S[inputLeftClassName]}>
                        {!isEmpty(label) ? (
                            <FormLabel
                                label={label}
                                id={id}
                                style={style}
                                disabled={disabled}
                                required={this.props.required}
                                labelClass={applyClassName(labelClassName, S, style)}
                            />
                        ) : null}
                        <input
                            autoFocus={autoFocus}
                            className={inputClass}
                            type={type}
                            ref={(c) => {
                                this._input = c;
                            }}
                            id={id}
                            value={value}
                            disabled={disabled}
                            onFocus={this._highlightOnFocusFirstTime.bind(this)}
                            onBlur={onTouched}
                            onChange={this._onChange.bind(this)}
                            onKeyDown={this._onKeyDown.bind(this)}
                            {...inputProps}
                        />
                        {this.displayAsInvalid() && showErrorMessage ? <FormErrors errors={errors} /> : null}
                    </div>
                    {help}
                </div>
            </div>
        );
    }
}
