import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

export default class CSSUtils {
    /**
     *
     * @param {string|Array}names
     * @param {object} originalStyle
     * @param {object} overrideStyle
     * @returns {string}
     */
    static applyClassName(names, originalStyle, overrideStyle) {
        if (isArray(names)) {
            return map(names, (n) => this.getClassName(n, originalStyle, overrideStyle)).join(' ');
        }
        return CSSUtils.getClassName(names, originalStyle, overrideStyle);
    }

    static getClassName(name, originalStyle, overrideStyle) {
        if (!isUndefined(overrideStyle) && has(overrideStyle, name)) {
            return overrideStyle[name];
        }

        if (!isUndefined(originalStyle) && has(originalStyle, name)) {
            return originalStyle[name];
        }
        return '';
    }

    /**
     * Returns the direct class name from a string representing a list of CSS
     * CSS module class names. The first class name is the name of the direct
     * class, whereas the following ones result from composition.
     */
    static getDirectClassName(moduleClasses) {
        return moduleClasses.split(' ')[0];
    }

    /**
     * Like `getDirectClassName` but returns a conventional CSS selector.
     */
    static getCSSSelector(moduleClasses) {
        return '.' + CSSUtils.getDirectClassName(moduleClasses);
    }
}
