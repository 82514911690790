import type {ComponentsProps} from '@material-ui/core/styles/props';

const componentProps: ComponentsProps = {
    MuiCheckbox: {
        color: 'primary',
    },
    MuiRadio: {
        color: 'primary',
    },
};

export default componentProps;
