export const CALL_SOCKET = Symbol('CALL SOCKET');
import isUndefined from 'lodash/isUndefined';
import * as actions from 'socket-io';

export default (store) => (next) => (action) => {
    if (!action) {
        return; //if the call was made to an operation that is not an action (such as download file), return
    }

    const socketAction = action[CALL_SOCKET];

    if (isUndefined(socketAction)) {
        return next(action);
    }

    // UNIT TEST flag is set in webpack config
    if (__UNIT_TEST__) {
        return next({
            type: 'API_SOCKET_CALLS_DISABLED',
        });
    }

    const fn = actions[socketAction.type];
    if (isUndefined(fn)) {
        console.error('Socket IO Action type was not found class.', socketAction);
        return;
    }

    fn(socketAction.eventName, socketAction.payload, socketAction.callback);
};
