// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/layout.less");
var ___CSS_LOADER_ICSS_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/border.less");
var ___CSS_LOADER_ICSS_IMPORT_2___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/colors.less");
var ___CSS_LOADER_ICSS_IMPORT_3___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/typography.less");
var ___CSS_LOADER_ICSS_IMPORT_4___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/less-loader/dist/cjs.js!styles/form.less");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("images/sc-logo-short.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_2___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_3___, "", true);
exports.i(___CSS_LOADER_ICSS_IMPORT_4___, "", true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".request-reset-password__main___1LlQt {\n  width: 39rem;\n}\n.request-reset-password__label___3UYV3 {\n}\n.request-reset-password__form___2Iyqe {\n  top: calc(50vh - 25rem);\n  padding: 1.9rem 3.4rem 2.9rem 3.4rem;\n  position: relative;\n}\n.request-reset-password__form___2Iyqe:before {\n  content: '';\n  display: block;\n  height: 4.5rem;\n  margin-top: 2rem;\n  margin-bottom: 1.5rem;\n  background-size: contain;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") scroll no-repeat 50% 0;\n}\n.request-reset-password__form___2Iyqe label {\n  display: block;\n  font-weight: 600;\n  padding: 0.9rem 0 0.6rem 0;\n}\n.request-reset-password__form___2Iyqe .request-reset-password__form_controls___3eYb3 {\n  margin-top: 1.5rem;\n  height: 3.3rem;\n}\n.request-reset-password__form___2Iyqe .request-reset-password__form_controls___3eYb3 p {\n  float: left;\n  line-height: 3.3rem;\n}\n.request-reset-password__form___2Iyqe .request-reset-password__form_controls___3eYb3 button {\n  float: right;\n}\n.request-reset-password__controlsContainer___1twFh {\n  text-align: right;\n  padding-top: 1rem;\n}\n.request-reset-password__controlsContainer___1twFh > button:not(:last-of-type) {\n  margin-right: 2rem;\n}\n.request-reset-password__errorMessage___1f3Uh {\n  padding-top: 1.5rem;\n}\n.request-reset-password__errorText___3Uvy0 {\n}\n.request-reset-password__input___3zGYo {\n  width: 29rem;\n}\n.request-reset-password__resetDescription___1kMa5 {\n  padding-bottom: 1.5rem;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "request-reset-password__main___1LlQt " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["center"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["fillHeightMinusFooter"] + "",
	"label": "request-reset-password__label___3UYV3 " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["uppercase"] + " " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["tiny"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["darkGray"] + "",
	"form": "request-reset-password__form___2Iyqe " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["basic"] + " " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["whiteBG"] + "",
	"form_controls": "request-reset-password__form_controls___3eYb3",
	"controlsContainer": "request-reset-password__controlsContainer___1twFh",
	"errorMessage": "request-reset-password__errorMessage___1f3Uh",
	"errorText": "request-reset-password__errorText___3Uvy0 " + ___CSS_LOADER_ICSS_IMPORT_2___.locals["error"] + " " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["small"] + "",
	"input": "request-reset-password__input___3zGYo " + ___CSS_LOADER_ICSS_IMPORT_4___.locals["inputValid"] + "",
	"resetDescription": "request-reset-password__resetDescription___1kMa5 " + ___CSS_LOADER_ICSS_IMPORT_3___.locals["normal"] + ""
};
module.exports = exports;
