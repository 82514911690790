import * as Immutable from 'immutable';
import {io} from 'socket.io-client';

import forEach from 'lodash/forEach';
import indexOf from 'lodash/indexOf';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import remove from 'lodash/remove';
import union from 'lodash/union';
import values from 'lodash/values';

import SocketIOEvents from 'shared-socket-io/socket-io-events';
import {IO_NAMESPACES, validSocketNamespace} from 'shared-socket-io/socket-io-namespaces';

import {getApiRoot} from 'utils/api-utils';
import MeteorCookies from 'utils/meteor-cookies';

/**
 * @typedef {import('socket.io-client').Socket} Socket
 */

/**
 * @typedef {object} SocketTokenAuth
 * @property {string} token
 */

/**
 * @typedef {object} SocketExamInterfaceTokenAuth
 * @property {string} examInterfaceToken
 */

let socket = null;
let subscribedEvents = [];
let subscribedEventsCallbacks = {};
let socketConnected = false;

export const getSocket = () => {
    return socket;
};

export const getSocketStatus = () => {
    return socketConnected;
};

/*
 * Methods for keeping track of the socket status and subscribing to said status.
 * Unnecessarily convoluted to fit into our actions/middleware method of interacting
 * with this SocketIO instance.
 * Event and payload are undefined. Callback is the only useful part.
 */
let statusCallbacks = Immutable.Set();

export const subscribeToStatus = (event, payload, callback) => {
    statusCallbacks = statusCallbacks.add(callback);
    callback(socketConnected); // immediately report back the current status
};

export const unsubscribeToStatus = (event, payload, callback) => {
    statusCallbacks = statusCallbacks.delete(callback);
};
// End SocketIO Status

export const disconnect = () => {
    if (!isNull(socket)) {
        socket.disconnect();
        subscribedEventsCallbacks = {};
        subscribedEvents = [];
        socket = null;
    }
};

export const getSocketTokenAuth = () => {
    const token = MeteorCookies.getInstance().get('token');
    return {
        token,
    };
};

/**
 * Connects to the socket endpoint on a SimCapture API instance, and authenticates using the provided `auth`
 * information. Returns the socket once instantiated. If you have to use legacy socket events that are piped through our
 * redux middleware, use the socket singleton created by `initializeSocketIo` instead.
 *
 * @param {string} namespace
 * @param {SocketTokenAuth | SocketExamInterfaceTokenAuth} auth
 * @returns {Socket}
 */
export const connectSocketIO = (namespace = IO_NAMESPACES.DEFAULT, auth = getSocketTokenAuth()) => {
    if (!validSocketNamespace(namespace)) {
        console.error('Only `SocketIOEvents` are supported.');
        return;
    }
    const apiRootAndNamespace = `${getApiRoot()}${namespace}`;
    const socket_ = io(apiRootAndNamespace, {
        auth,
        transports: ['websocket'],
        upgrade: false,
    });

    return socket_;
};

/**
 * Async version of `connectSocketIO`.
 *
 * @param {string} namespace
 * @param {SocketTokenAuth | SocketExamInterfaceTokenAuth} auth
 * @returns {Promise<Socket>}
 */
export const connectSocketIOAsync = async (namespace, auth) => {
    const socket_ = connectSocketIO(namespace, auth);

    return new Promise((resolve, reject) => {
        socket_.on('connect', () => {
            resolve(socket_);
        });
        socket_.on('connect_error', (err) => {
            reject(err);
        });
    });
};

/**
 * Connects to the socket endpoint on a SimCapture API instance, and authenticates using the SimCapture auth token from
 * the cookies. Returns the created socket instance, and also saves it as a singleton which is used by other methods
 * exported by this file.
 *
 * @deprecated Conflates socket responsibilities with redux actions and stores. Implements its own subscription system
 * that can just be replaced with socket.io's own `on` and `emit`.
 * @param reduxStore Our global redux store that we use for all our redux stuff
 * @returns {Socket}
 */
export const initializeSocketIo = (reduxStore) => {
    disconnect();

    socket = io(getApiRoot(), {
        auth: getSocketTokenAuth(),
        transports: ['websocket'],
        upgrade: false,
    });

    socket.on('connect', () => {
        socketConnected = true;
        statusCallbacks.forEach((cb) => cb(socketConnected));
    });

    // from socket.io 3.x onward, the `reconnect` event is on the manager instance, not the socket
    socket.io.on('reconnect', () => {
        socket.emit('subscribe', subscribedEvents);
    });

    socket.on('disconnect', () => {
        socketConnected = false;
        statusCallbacks.forEach((cb) => cb(socketConnected));
    });

    forEach(SocketIOEvents, (e) => {
        socket.on(e, ({id, data}) => {
            reduxStore.dispatch({type: e, data});

            if (subscribedEventsCallbacks[e + id]) {
                subscribedEventsCallbacks[e + id](data);
            }
        });
    });

    socket.on('connect_error', (err) => {
        console.error('Failed to establish socket.io connection:', err);
    });

    return socket;
};

// As far as I can tell, the `id` is an arbitrary unique id that allows the
// events to go to a specific subscriber, if the event object has an `id` that
// matches the subscriber's `id`.
export const subscribe = (event, id, callback) => {
    if (isNull(socket)) {
        return;
    }

    if (isNil(id)) {
        // technically this is fine, but it's probably a mistake and we should refactor the subscription
        console.error(`Subscribing to ${event} without an id.`);
    }

    id = id || '';
    if (indexOf(values(SocketIOEvents), event) !== -1) {
        subscribedEvents = union(subscribedEvents, [event + id]);
        subscribedEventsCallbacks[event + id] = callback;
        socket.emit('subscribe', subscribedEvents);
    } else {
        console.error('Only `SocketIOEvents` are supported.');
    }
};

export const unsubscribe = (event, id) => {
    if (isNull(socket)) {
        return;
    }

    if (isNil(id)) {
        // technically this is fine, but it's probably a mistake and we should refactor the subscription
        console.error(`Unsubscribing to ${event} without an id.`);
    }

    id = id || '';
    if (indexOf(values(SocketIOEvents), event) !== -1) {
        // this method mutates subscribedEvents, removing the event we're unsubscribing from
        remove(subscribedEvents, (evt) => evt === event + id);
        delete subscribedEventsCallbacks[event + id];
        socket.emit('unsubscribe', [event + id]);
    } else {
        console.error('Only `SocketIOEvents` are supported.');
    }
};

/**
 * @typedef {object} SubscribeRequest
 * @property {string} event
 * @property {string} id
 * @property {function} callback
 */

/**
 * Subscribe to multiple events
 * @param {SubscribeRequest[]} subscribeRequests
 */
export const subscribeBulk = (subscribeRequests) => {
    if (isNull(socket)) {
        return;
    }

    const events = [];

    subscribeRequests.forEach(({event, id, callback}) => {
        if (isNil(id)) {
            console.error(`Subscribing to ${event} without an id.`);
        }
        id = id || '';
        if (indexOf(values(SocketIOEvents), event) !== -1) {
            subscribedEvents = union(subscribedEvents, [event + id]);
            subscribedEventsCallbacks[event + id] = callback;

            events.push(event + id);
        } else {
            console.error('Only `SocketIOEvents` are supported.');
        }
    });

    socket.emit('subscribe', events);
};

/**
 * Unsubscribe from multiple events
 * @param {string[]} events
 */
export const unsubscribeBulk = (events) => {
    if (isNull(socket)) {
        return;
    }

    events.forEach((event) => {
        remove(subscribedEvents, (evt) => evt === event);
        delete subscribedEventsCallbacks[event];
    });
    socket.emit('unsubscribe', events);
};

// deprecated alias, the implementation is now at `unsubscribe`
export const unSubscribe = unsubscribe;

const emitAsync = function (socket_, event, payload) {
    return new Promise((resolve, reject) => {
        socket_.emit(event, payload, (response) => {
            if (response.status < 400) {
                resolve(response);
            } else {
                reject(response);
            }
        });
    });
};

/**
 * Adds a one-time listener function for the event named eventName. The next time eventName is triggered, this listener is removed and then invoked.
 * @param {string|symbol} eventName
 * @param {Function} callback
 * @returns {import('socket.io-client').Socket}
 */
export const on = function (eventName, callback, socket_ = socket) {
    return socket_.on(eventName, callback);
};

/**
 * Removes the specified listener from the listener array for the event named eventName.
 * @param {string|symbol} eventName
 * @param {Function} callback
 * @returns {import('socket.io-client').Socket}
 */
export const off = function (eventName, callback, socket_ = socket) {
    if (isNil(socket_)) {
        return;
    }

    return socket_.off(eventName, callback);
};

/**
 * Returns a promise that resolves to the response received for the emit. Does
 * not handle error responses from the backend; just blindly returns the error
 * response like a normal response.
 */
export const emit = (event, payload, socket_ = socket) => {
    if (isNil(socket_)) {
        return;
    }

    if (indexOf(values(SocketIOEvents), event) !== -1) {
        return emitAsync(socket_, event, payload);
    } else {
        console.error('Only `SocketIOEvents` are supported.');
    }
};
