import type {PaletteOptions} from '@material-ui/core/styles/createPalette';

/*
 * Color Palette Guide =
 *
 * The number indicates a relative lightness/darkness for each color gradient.
 * The colors transition from highest value to lowest value as the numbers increase.
 * The hue is generally constant in each range.
 *
 * 020 - 200 will typically be reserved for backgrounds
 */

type SCPaletteOptions = {
    primary: {
        20: string;
    };
    secondary: {
        20: string;
    };
    error: {
        20: string;
    };
    warning: {
        20: string;
    };
    info: {
        20: string;
    };
    success: {
        20: string;
    };
    grey: {
        20: string;
    };
};

const paletteCommon: Partial<PaletteOptions> & SCPaletteOptions = {
    common: {
        white: '#FFFFFF',
        black: '#1F1F1F',
    },
    primary: {
        main: '#3294BA',
        contrastText: '#FFFFFF',
        20: '#ECF8FD',
        50: '#ECF8FD',
        100: '#D9F1FA',
        200: '#B2E3F6',
        300: '#79CEEF',
        400: '#3FB9E8',
        500: '#3294BA',
        600: '#266F8B',
        700: '#205D74',
        800: '#133746',
    },
    // Accent 1
    secondary: {
        main: '#31B395',
        contrastText: '#FFFFFF',
        20: '#EDFDF9',
        50: '#EDFDF9',
        100: '#C5F8EC',
        200: '#93F2DC',
        300: '#3BDBB6',
        400: '#36C8A7',
        500: '#31B395',
        600: '#2A9A80',
        700: '#217965',
        800: '#13473B',
    },
    //Critical
    error: {
        main: '#DC3449',
        contrastText: '#FFFFFF',
        20: '#FEF5F7',
        50: '#FEF5F7',
        100: '#FDDEE2',
        200: '#FDBAC2',
        300: '#FD96A4',
        400: '#FC5A6F',
        500: '#DC3449',
        600: '#C32238',
        700: '#9C0D1D',
        800: '#6B0613',
    },
    warning: {
        main: '#C45402',
        contrastText: '#FFFFFF',
        20: '#FEF7F1',
        50: '#FEF7F1',
        100: '#FCDDC5',
        200: '#F8C096',
        300: '#F4A162',
        400: '#E97116',
        500: '#C45402',
        600: '#AD4B00',
        700: '#803700',
        800: '#572600',
    },
    // Accent 2
    info: {
        main: '#BC9D33',
        contrastText: '#FFFFFF',
        20: '#FDF9EE',
        50: '#FDF9EE',
        100: '#F8EDC8',
        200: '#F4E09F',
        300: '#EED171',
        400: '#E7C03F',
        500: '#BC9D33',
        600: '#A2862C',
        700: '#806A23',
        800: '#4B3E14',
    },
    // Correct
    success: {
        main: '#14892F',
        contrastText: '#FFFFFF',
        20: '#ECFEF1',
        50: '#ECFEF1',
        100: '#CCEED2',
        200: '#A0D9AD',
        300: '#77C589',
        400: '#37A851',
        500: '#14892F',
        600: '#13772A',
        700: '#025A15',
        800: '#003D0E',
    },
    // Neutral
    grey: {
        20: '#F8F9FA',
        50: '#F8F9FA',
        100: '#EBEEEF',
        200: '#DCE2E4',
        300: '#CCD4D8',
        400: '#BBC6CA',
        500: '#A7B5BB',
        600: '#91A3AA',
        700: '#616E74',
        800: '#394144',
    },
};

/**
 * This should only be imported for creating a theme object. Access to the properties should be done through the theme
 * object provided by a ThemeProvider.
 */
export const PaletteLight: PaletteOptions = {
    type: 'light',
    text: {
        // Body
        primary: '#5B5B5B',
        // Dark
        secondary: '#333333',
        // Muted
        disabled: '#767676',
        hint: '#767676',
    },
    background: {
        default: '#FAFAFA',
        paper: '#FFFFFF',
    },
    divider: '#E0E0E0',
    ...paletteCommon,
};

export const PaletteDark: PaletteOptions = {
    type: 'dark',
    text: {
        // Body
        primary: '#BBC6CA',
        // Dark
        secondary: '#F8F9FA',
        // Muted
        disabled: '#616E74',
        hint: '#616E74',
    },
    background: {
        default: '#292A30',
        paper: '#3E3F48',
    },
    divider: '#51525d',
    ...paletteCommon,
};
