import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

import S from './sc-circular-progress.less';

import type {CircularProgressProps} from '@material-ui/core/CircularProgress/CircularProgress';

const mediumSize = '2.5rem';
const smallSize = '2rem';
const thickness = 7;

// SCLD-14893 conform to the medium size of 48px defined in Laerdal Life and migrate existing usages of the existing 25px size to either the 48px medium size or the 20px small size
type Size = 'small' | 'medium';

type Props = CircularProgressProps & {size?: Size};

const SCCircularProgress = (props: Props) => {
    const {size = 'medium', ...circularProgressProps} = props;

    const sizeRem = size === 'small' ? smallSize : mediumSize;

    return (
        <div className={S.root}>
            <CircularProgress
                variant='determinate'
                className={S.bottom}
                size={sizeRem}
                thickness={thickness}
                role={null}
                {...circularProgressProps}
                value={100}
            />
            <CircularProgress
                variant='indeterminate'
                disableShrink={true}
                className={S.top}
                size={sizeRem}
                thickness={thickness}
                {...circularProgressProps}
            />
        </div>
    );
};

export default SCCircularProgress;
