import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import S from './form-errors.less';

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';

const {oneOfType, object, array, string} = PropTypes;
/**
 * @deprecated Use Formik and material-ui components instead or refactor this component.
 */
const FormErrors = ({errors}) => {
    const renderError = (error) => {
        if (!isUndefined(error.id)) {
            return <FormattedMessage {...error} />;
        }
        if (isObject(error)) {
            return; // if it's an object and doesn't have an id, we can't display it
        }
        return <span>{error}</span>;
    };

    return (
        <div className={S.container}>
            {isArray(errors) ? (
                <ul>
                    {errors.map((error, key) => {
                        return (
                            <li className={S.li} key={key}>
                                {renderError(error)}
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <span className={S.text}>{renderError(errors)}</span>
            )}
        </div>
    );
};

FormErrors.propTypes = {
    errors: oneOfType([object, array, string]),
};

FormErrors.defaultProps = {
    errors: {},
};

export default FormErrors;
