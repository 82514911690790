import PropTypes from 'prop-types';
import * as Immutable from 'immutable';

import isNil from 'lodash/isNil';

import Tooltip from '@material-ui/core/Tooltip';

import S from './pass-through-login.less';

const {func, instanceOf, string} = PropTypes;

/**
 * Allows users to one-click login as reserved users on sample clients. This
 * component is intended for testing only. It should never appear on any staging
 * or production environment.
 */
export default class PassThroughLogin extends React.PureComponent {
    static propTypes = {
        onLogin: func.isRequired,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof List' is not assignable t... Remove this comment to see the full error message
        clients: instanceOf(Immutable.List).isRequired,
        title: string.isRequired,
    };

    constructor() {
        super();
        this.loginKeydown = this.loginKeydown.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.loginKeydown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.loginKeydown);
    }

    loginKeydown = (event) => {
        const {clients} = this.props;
        if ((event.ctrlKey || event.metaKey) && event.altKey && event.keyCode > 48 && event.keyCode < 58) {
            const client = clients.get(event.keyCode - 49);
            if (!isNil(client)) {
                this.props.onLogin(getLoginCredentials(client));
            }
        }
    };

    loginClick = (client) => () => this.props.onLogin(getLoginCredentials(client));

    render() {
        const {clients, title} = this.props;
        return (
            <div className={S.container}>
                <div>
                    <p className={S.title}>{title}</p>
                    <ol>
                        {clients.map((client) => (
                            <li className={S.client} onClick={this.loginClick(client)} key={client.get('clientId')}>
                                <span className={S.clientName}>{client.get('clientSubdomain')}</span>
                                {client.get('hasPdl') && (
                                    <Tooltip title='This client has the SimCapture for Skills feature enabled'>
                                        <span className={S.hasPdl}>Skills</span>
                                    </Tooltip>
                                )}
                            </li>
                        ))}
                    </ol>
                    <p className={S.instructions}>Instructions:</p>
                    <ul>
                        <li className={S.instruction}>Click on the client to login, or</li>
                        <li className={S.instruction}>Mac: ⌘ + ⌥ + number</li>
                        <li className={S.instruction}>PC: Ctrl + Alt + number</li>
                    </ul>
                </div>
            </div>
        );
    }
}

const getLoginCredentials = (client) => {
    return Immutable.Map({
        clientSubdomain: client.get('clientSubdomain'),
        username: client.get('username'),
        password: client.get('password'),
    });
};
