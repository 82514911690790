import PropTypes from 'prop-types';
import * as Immutable from 'immutable';
import PassThroughLogin from './pass-through-login';

const SAMPLE_CLIENTS = Immutable.fromJS([
    {
        clientSubdomain: 'imsh',
        clientId: 'a30c332f-4eb3-5eb1-9d8a-a720afcf9bb4',
        hasPdl: true,
    },
    {
        clientSubdomain: 'tier3',
        clientId: '17cef0b5-3e06-4081-b99a-a7b862087b61',
        hasPdl: false,
    },
    {
        clientSubdomain: 'pro-tier-1',
        clientId: 'c4a431cc-3a75-4c20-9bd8-8b68c38af5b9',
        hasPdl: false,
    },
    {
        clientSubdomain: 'test',
        clientId: 'e5c5d386-dfb6-4ec1-aa3b-3c09c8f51798',
        hasPdl: false,
    },
    {
        clientSubdomain: 'pro-tier-2',
        clientId: '633c9e4a-2c90-4899-85f5-747bea781f12',
        hasPdl: true,
    },
    {
        clientSubdomain: 'test2',
        clientId: 'd3b6402f-7d44-4685-b59d-d5a8b90e0691',
        hasPdl: false,
    },
]);

const clients = SAMPLE_CLIENTS.map((client) => {
    return client.set('username', client.get('clientId')).set('password', 'admin');
});

const LocalDevLogin = ({onLogin}) => {
    return <PassThroughLogin onLogin={onLogin} clients={clients} title='Local Environment Pass-Through' />;
};

LocalDevLogin.propTypes = {
    onLogin: PropTypes.func.isRequired,
};

export default LocalDevLogin;
