import {page_width_medium} from 'mui/mixins/legacy-queries';

import type {IsolationProperty} from 'csstype';

//#region Layout from layout.less

const iconSmallWidth = '3rem';
const iconSmallHeight = '2rem';

const iconStandardWidth = '3.5rem';
const iconStandardHeight = '3rem';
const iconStandardFontSize = '2rem';

const iconHeadingWidth = '7rem';
const iconHeadingHeight = '6rem';
const iconHeadingFontSize = '3rem';

const iconNavigationHeight = '5rem';
const iconNavigationFontSize = '2.4rem';

const smallItemHeight = '3rem';

const itemHeight = '4.5rem';
const itemWidth = '5.5rem';

const mediumItemHeight = '6rem';
const mediumItemWidth = '7rem';

const bigItemWidth = '9rem';

const headerHeight = '8rem';
const headerCondensedHeight = '0.5rem';
const footerHeight = '5rem';

const verticalMargin = '2rem';
const basePadding = '2rem';

const podWidth = '44.5rem';
const podTitleHeight = '4.5rem';

const globalNavHeight = iconNavigationHeight;
const navigationClearance = '0.5rem';

const pageMarginLR = '4rem';

//#endregion

const legacyLayoutMixin = {
    minPageWidth: {
        minWidth: page_width_medium,
    },
    fullPageHeight: {
        minHeight: `calc(~'100vh - 40px')`,
        '@media @da_medium': {
            minHeight: '100vh',
        },
    },
    navigationHeight: {
        height: globalNavHeight,
    },
    navigationClearance: {
        paddingTop: `${globalNavHeight} + ${navigationClearance}`,
    },
    navigationMargin: {
        marginTop: navigationClearance,
    },
    headerHeight: {
        height: headerHeight,
    },
    headerLineHeight: {
        lineHeight: headerHeight,
    },
    headerCondensedHeight: {
        height: headerCondensedHeight,
    },
    headerCondensedLineHeight: {
        lineHeight: headerCondensedHeight,
    },
    globalNavTop: {
        top: globalNavHeight,
    },
    headerTop: {
        top: headerHeight,
    },
    headerCondensedTop: {
        top: headerCondensedHeight,
    },
    minWidth: {
        minWidth: '76.8rem',
    },
    center: {
        margin: '0 auto',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
    clear_floats: {
        '&:after': {
            float: 'none',
            content: '',
            clear: 'both',
            display: 'block',
        },
    },
    scroll: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        boxSizing: 'border-box',
        paddingTop: '2rem',
        paddingBottom: '2rem',
    },
    pageMargins: {
        paddingTop: verticalMargin,
        paddingLeft: pageMarginLR,
        paddingRight: pageMarginLR,
    },
    ignorePageMarginR: {
        marginRight: '-4rem',
    },
    fillHeight: {
        minHeight: '100vh',
    },
    fillHeightMinusVerticalMargin: {
        minHeight: `calc(~'100vh - ' ${verticalMargin})`,
    },
    fillHeightMinusFooter: {
        minHeight: `calc(~'100vh - ' ${footerHeight})`,
    },
    fillHeightMinusHeader: {
        height: `calc(~'100vh - ' (${headerHeight} + ${verticalMargin}))`,
    },
    footerHeight: {
        height: footerHeight,
        lineHeight: footerHeight,
    },
    // Box Items
    smallItemHeight: {
        height: smallItemHeight,
    },
    itemHeight: {
        height: itemHeight,
    },
    containerLessItemHeight: {
        height: `calc(~'100% - ${itemHeight},')`,
    },
    itemMinHeight: {
        minHeight: itemHeight,
    },
    mediumItemHeight: {
        height: mediumItemHeight,
    },
    bigItemHeight: {
        composes: mediumItemHeight,
    },
    squareBoxItem: {
        composes: itemHeight,
        width: itemHeight,
        lineHeight: itemHeight,
    },
    // -----------------------------
    // defines sizing for icons, to be used with IconBox component
    iconItemStandardFontSize: {
        fontSize: iconStandardFontSize,
    },
    iconItemStandardHeight: {
        height: iconStandardHeight,
    },
    iconItemStandardWidth: {
        width: iconStandardWidth,
    },
    iconItemStandardLineHeight: {
        lineHeight: iconStandardHeight,
    },
    iconItemHeadingFontSize: {
        fontSize: iconHeadingFontSize,
    },
    iconItemNavigationFontSize: {
        fontSize: iconNavigationFontSize,
    },
    iconItemNavigationHeight: {
        height: iconNavigationHeight,
        lineHeight: iconNavigationHeight,
    },
    iconItem: {
        '&Small': {
            width: iconSmallWidth,
            height: iconSmallHeight,
        },
        '&Standard': {
            width: iconStandardWidth,
            height: iconStandardHeight,
        },
        '&Heading': {
            width: iconHeadingWidth,
            height: iconHeadingHeight,
        },
    },
    iconItemNavigation: {
        width: '5rem',
        // composes: iconItemNavigationHeight
        height: iconNavigationHeight,
        lineHeight: iconNavigationHeight,
    },
    // -----------------------------
    // -----------------------------
    boxItem: {
        composes: itemHeight,
        width: itemWidth,
    },
    mediumBoxItem: {
        composes: mediumItemHeight,
        width: mediumItemWidth,
    },
    bigBoxItem: {
        composes: mediumItemHeight,
        width: bigItemWidth,
    },
    boxItemVariableWidth: {
        composes: itemHeight,
        minWidth: itemWidth,
    },
    smallItemLineHeight: {
        lineHeight: smallItemHeight,
    },
    itemLineHeight: {
        lineHeight: itemHeight,
    },
    mediumItemLineHeight: {
        lineHeight: mediumItemHeight,
    },
    bigItemLineHeight: {
        // composes: mediumItemLineHeight
        lineHeight: mediumItemHeight,
    },
    // Padding
    squareBoxPL: {
        paddingLeft: itemHeight,
    },
    boxPL: {
        paddingLeft: itemWidth,
    },
    mediumBoxPL: {
        paddingLeft: mediumItemWidth,
    },
    bigBoxML: {
        marginLeft: bigItemWidth,
    },
    bigBoxPL: {
        paddingLeft: bigItemWidth,
    },
    iconPL: {
        paddingLeft: iconStandardWidth,
    },
    innerContentPaddingH: {
        paddingLeft: basePadding,
        paddingRight: basePadding,
    },
    innerContentPaddingV: {
        paddingTop: basePadding,
        paddingBottom: basePadding,
    },
    innerContent: {
        // composes: innerContentPaddingH,
        paddingLeft: basePadding,
        paddingRight: basePadding,
        // composes: innerContentPaddingV,
        paddingTop: basePadding,
        paddingBottom: basePadding,
    },
    podWidth: {
        width: podWidth,
    },
    podTitleHeight: {
        height: podTitleHeight,
        lineHeight: podTitleHeight,
    },
    podMenuButtonWidth: {
        width: podTitleHeight,
    },
    tableCellPadding: {
        padding: '0.5rem',

        '&:first-child': {
            paddingLeft: '1rem',
        },
        '&:last-child': {
            paddingRight: '1rem',
        },
    },
    /**
     * The creation of a stacking context through the `isolate` class, and the
     * z-indices defined for the children of the isolated element, should order
     * those children strictly and prevent any content from any of their descendants
     * from breaking out of the defined order, regardless of what z-index or
     * `position` value is given to said descendants. Note that positioned elements
     * (with z-indices) are above any non-positioned elements.
     */
    // ensure z-index settings within different layers do not interfere with each other
    isolate: {
        isolation: 'isolate' as IsolationProperty,
    },
};

export default legacyLayoutMixin;
