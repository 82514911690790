import PropTypes from 'prop-types';
import * as Immutable from 'immutable';
import PassThroughLogin from './pass-through-login';

const SAMPLE_CLIENTS = Immutable.fromJS([
    {
        clientSubdomain: 'ucsd',
        clientId: '5c8f6db1-80aa-471b-a247-d7fa3b7ebaa2',
        username: 'Bridgette14',
    },
    {
        clientSubdomain: 'bidmc',
        clientId: '5185bd08-e475-4110-a431-b2d6c87e51bc',
        username: 'Yasmin.OKeefe74',
    },
    {
        clientSubdomain: 'mnu-pro',
        clientId: '9684f200-01f8-4223-88d5-b5cb67bfac89',
        username: 'Carmel_Swaniawski',
    },
    {
        clientSubdomain: 'training-ccm',
        clientId: '831b0a03-7ab8-418e-ae1d-213a5d46736c',
        username: 'Marlee83',
    },
    {
        clientSubdomain: 'vmed',
        clientId: 'b4f6cfe1-a9a5-4a75-a5a7-6fd28f77e4cf',
        username: 'Tiana96',
    },
]);

const clients = SAMPLE_CLIENTS.map((client) => {
    return client.set('password', 'cube');
});

const FeatureBranchLogin = ({onLogin}) => {
    return <PassThroughLogin onLogin={onLogin} clients={clients} title='Feature Branch Pass-Through' />;
};

FeatureBranchLogin.propTypes = {
    onLogin: PropTypes.func.isRequired,
};

export default FeatureBranchLogin;
